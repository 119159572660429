import "./Onboarding.css"

export const SignupSuccessPage = () => {
  return (
    <div className="signup-success-page">
      <div className="signup-success-title">Thank you for registering!</div>
      <div className="signup-success-subtitle">
        Our team will send you a confirmation email shortly.
      </div>
    </div>
  )
}
