import { memo, useMemo, useState, useEffect, Fragment } from "react"

import { UISheet, UICell } from "../../types/UITypes"
import "./SheetRenderer.css"
import CellLabelRenderer from "./CellLabelRenderer"
import clsx from "clsx"

type CustomNodeProps = { id: string; data: UISheet }

export const MatrixPreviewRenderer = ({ id, data }: CustomNodeProps) => {
  let numSheetColumns = 1

  // TODO: add the dimension property to the sheet
  data.cells.forEach((cell) => {
    if (cell.columnIndex > numSheetColumns) {
      numSheetColumns = cell.columnIndex
    }
  })

  const sortedCells = useMemo(() => {
    return [...data.cells].sort(
      (a, b) =>
        a.rowIndex * 100 + a.columnIndex - (b.rowIndex * 100 + b.columnIndex)
    )
  }, [data.cells, data.columnLabels])

  const sortedColumnLabels = useMemo(() => {
    const result = []
    for (let columnIndex = 1; columnIndex <= numSheetColumns; columnIndex++) {
      result.push(
        (data.columnLabels || []).find(
          (columnLabel) => columnLabel.columnIndex === columnIndex
        )?.text || ""
      )
    }
    return result
  }, [data.columnLabels])

  if (!sortedCells?.length) {
    return null
  }

  return (
    <div className="sheet-preview" style={{ display: "flex" }}>
      <div
        key={id}
        className="sheet"
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${
            sortedCells[sortedCells.length - 1].columnIndex + 1
          }, max-content)`,
          gridTemplateRows: `repeat(${
            sortedCells[sortedCells.length - 1].rowIndex
          }, max-content)`,
        }}
      >
        <>
          <div className="matrix-top-left-cell preview-sheet-cell">
            <div className="matrix-x-label">
              {data.columnLabelsLabel || "Columns"}
            </div>
            <div className="matrix-y-label">
              {data.rowLabelsLabel || "Rows"}
            </div>
          </div>
          {sortedColumnLabels.map((label, i) => (
            <div
              key={`columnLabel-${i}`}
              className={clsx(
                "cell",
                "preview-sheet-cell",
                "preview-sheet-column-label",
                {
                  "preview-sheet-cell-last-column":
                    i + 1 === sortedCells[sortedCells.length - 1].columnIndex,
                }
              )}
            >
              {label}
            </div>
          ))}
          {sortedCells.map((cell: UICell, i) => (
            <Fragment key={`matrix-cell-preview-${i}`}>
              {cell.columnIndex === 1 && data.complex ? (
                <div
                  key={`rowLabel-${i}`}
                  className={clsx(
                    "cell",
                    "preview-sheet-cell",
                    "preview-sheet-column-label"
                  )}
                >
                  {(data.rowLabels || [])[cell.rowIndex - 1]?.text || ""}
                </div>
              ) : null}
              <div
                key={cell.id}
                id={`cell-${cell.id}`}
                className={clsx("cell preview-sheet-cell", {
                  "preview-sheet-cell-last-row":
                    cell.rowIndex ===
                    sortedCells[sortedCells.length - 1].rowIndex,
                  "preview-sheet-cell-last-column":
                    cell.columnIndex ===
                    sortedCells[sortedCells.length - 1].columnIndex,
                })}
              >
                <CellLabelRenderer cell={cell} />
              </div>
            </Fragment>
          ))}
        </>
      </div>
    </div>
  )
}

export default memo(MatrixPreviewRenderer)
