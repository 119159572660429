import { ApiCell, ApiLogic, PublishedApi } from "../database/SupabaseConnector"

const addCellsDependencies = (
  rootCell: ApiCell | ApiLogic,
  cellsDependencyTree: Record<string, unknown[]>,
  currentCell?: ApiCell | ApiLogic
) => {
  if (!cellsDependencyTree[rootCell.id]) {
    cellsDependencyTree[rootCell.id] = []
  }

  if (!(currentCell as ApiLogic).logicType) {
    cellsDependencyTree[rootCell.id].push(currentCell)

    if (currentCell?.prev) {
      addCellsDependencies(
        rootCell,
        cellsDependencyTree,
        currentCell?.prev as ApiCell | ApiLogic
      )
    }
  } else {
    const logicPrevNodes = (currentCell as ApiLogic).prev

    cellsDependencyTree[rootCell.id].push(currentCell)

    logicPrevNodes.forEach((cellOrLogic) => {
      addCellsDependencies(rootCell, cellsDependencyTree, cellOrLogic)
      addCellsDependencies(cellOrLogic, cellsDependencyTree, cellOrLogic)
    })
  }
}
const uniqueOnly = (value: string, index: number, array: string[]) => {
  return array.indexOf(value) === index
}

export const buildCellsDependencyTree = (apis: PublishedApi[]) => {
  const cellsDependencyTreeSource: Record<string, unknown[]> = {}
  const cellsDependencyTree: Record<
    string,
    { logics: string[]; cells: string[] }
  > = {}

  apis.forEach((api) => {
    api.cells.forEach((cell) => {
      addCellsDependencies(cell, cellsDependencyTreeSource, cell)
    })
  })

  // TODO: figure out why same values end up in the array and fix it
  for (let [key, value] of Object.entries(cellsDependencyTreeSource)) {
    cellsDependencyTree[key] = {
      cells: value
        .filter((cellOrLogic) => !(cellOrLogic as ApiLogic).logicType)
        .map((cell) => (cell as ApiCell).id)
        .filter(uniqueOnly),
      logics: value
        .filter((cellOrLogic) => (cellOrLogic as ApiLogic).logicType)
        .map((cell) => (cell as ApiLogic).id)
        .filter(uniqueOnly),
    }
  }

  return cellsDependencyTree
}
