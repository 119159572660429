import clsx from "clsx"
import { UICell } from "../../types/UITypes"
import { operators } from "../../utils/constants"
import { AppContext } from "../../AppContext"
import { useContext } from "react"

const operatorsLabels = operators.reduce(
  (acc, curr) => ({ ...acc, [curr.value]: curr.label }),
  {} as Record<string, string>
)

type CellLabelRendererProps = {
  cell: UICell
  onClick?: () => void
}

export const CellLabelRenderer = ({
  cell,
  onClick,
}: CellLabelRendererProps) => {
  const { sheetValidationErrors } = useContext(AppContext)

  const sheetErrors = sheetValidationErrors[cell.sheetId]
  const cellError = sheetErrors && sheetErrors[cell.id]

  return (
    <div
      className={clsx("cell-label", {
        "cell-label-with-error": !!cellError,
      })}
      onClick={onClick}
    >
      <div>{cell.name}</div>
    </div>
  )
}

export default CellLabelRenderer
