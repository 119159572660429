export const operators = [
  { value: "eq", label: "=" },
  { value: "gt", label: ">" },
  { value: "ge", label: ">=" },
  { value: "lt", label: "<" },
  { value: "le", label: "<=" },
]

export const CanvasPermission = {
  PMBiz: "PMBiz",
  Dev: "Dev",
  CloudflareExport: "CloudflareExport",
  FirebaseExport: "FirebaseExport",
}

export const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

export const comparisonRegExpStr =
  "([a-zA-Z0-9_ .-]*)(=|<=|>=|>|<)([a-zA-Z0-9_ .-]*)"
export const comparisonRegExp = new RegExp(comparisonRegExpStr, "i")
export const comparisonShortFormRegExp = new RegExp(
  "(=|<=|>=|>|<)([a-zA-Z0-9_ .-]*)",
  "i"
)
export const comparisonFormulaRegExp = new RegExp(
  `^(=)${comparisonRegExpStr}`,
  "i"
)
const logicRegExpStr = "(AND|OR)(\\(.*\\))"
export const nestedLogicRegExp = new RegExp(logicRegExpStr, "i")
export const logicRegExp = new RegExp(`^(=)${logicRegExpStr}`, "i")

export const edgeSourceSheetHandleRegExp = new RegExp(
  "source-sheet-(.*)-row-(.*)"
)

export const edgeTargetSheetHandleRegExp = new RegExp(
  ".*target-sheet-(.*)-row-(\\d*).*"
)

export const SHORT_CELL_WIDTH = 150
export const CELL_WIDTH = 225
export const ROW_INDEX_WIDTH = 33
