import { useContext, useEffect, useRef, useState } from "react"
import TextareaAutosize from "react-textarea-autosize"
import { PastedCellContent, SheetField, UICell } from "../types/UITypes"
import "./FormulaEditor.css"
import { AppContext } from "../AppContext"

type FormulaEditorProps = {
  cell: UICell
  onConfirm: (value: string, cell: UICell) => void
  onClose?: () => void
  sheetFields: SheetField[]
  editingEnabled: boolean
}

export const FormulaEditor = (props: FormulaEditorProps) => {
  const { onConfirm, onClose, cell, editingEnabled } = props
  const [inlineEditorValue, setInlineEditorValue] = useState("")
  const [formulaHelperType, setFormulaHelperType] = useState("")
  const [formulaOperator, setFormulaOperator] = useState("")
  const [formulaCellName, setFormulaCellName] = useState("")
  const [formulaCellValue, setFormulaCellValue] = useState("")
  const { setEditedCell } = useContext(AppContext)

  const inlineEditorInputRef = useRef<HTMLTextAreaElement>(null)
  const confirmChangeTimeoutRef = useRef<number>()

  const handleInlineEditorValueChange = () => {
    if (!editingEnabled) {
      setEditedCell(cell)
    }

    if (!inlineEditorInputRef.current) {
      return
    }

    const target = inlineEditorInputRef.current
    const { value } = target

    setInlineEditorValue(value)
  }

  // const handleInlineEditorCursorChange = () => {
  //   if (!inlineEditorInputRef.current) {
  //     return
  //   }

  //   const target = inlineEditorInputRef.current
  //   const { value } = target

  //   if (target.selectionEnd !== target.selectionStart) {
  //     return
  //   }

  //   const formulaString = value.substring(0, target.selectionEnd)

  //   const formulaMatch5 = formulaString.match(formulaRegExpPart5)

  //   if (formulaMatch5 || formulaMatch4) {
  //     setFormulaHelperType("value")
  //   } else if (formulaMatch3) {
  //     setFormulaHelperType("name")
  //   } else if (formulaMatch2 || formulaMatch1) {
  //     setFormulaHelperType("operator")
  //   }
  // }

  const setEditorInputFocus = () => {
    if (inlineEditorInputRef.current) {
      inlineEditorInputRef.current.focus()
      inlineEditorInputRef.current.setSelectionRange(100, 100)
    }
  }

  const handleSelectOperator = (value: string) => {
    console.log({ formulaCellName, formulaCellValue })
    if (formulaCellName && formulaCellValue) {
      setInlineEditorValue(`=${value}(${formulaCellName},${formulaCellValue})`)
    } else if (formulaCellName) {
      setInlineEditorValue(`=${value}(${formulaCellName},`)
    } else {
      setInlineEditorValue(`=${value}(`)
    }
    setFormulaOperator(value)
    setFormulaHelperType("name")
    setEditorInputFocus()
  }

  const handleSelectFieldName = (value: string) => {
    if (formulaOperator && formulaCellName && formulaCellValue) {
      setInlineEditorValue(`=${formulaOperator}(${value},${formulaCellValue})`)
    } else if (formulaOperator) {
      setInlineEditorValue(`=${formulaOperator}(${value},`)
    }
    setFormulaCellName(value)
    setFormulaHelperType("value")
    setEditorInputFocus()
  }

  const handleSelectFieldValue = (value: string) => {
    if (formulaOperator && formulaCellName) {
      setInlineEditorValue(`=${formulaOperator}(${formulaCellName},${value})`)
    }
    setFormulaCellValue(value)
    setFormulaHelperType("")
    setEditorInputFocus()
  }

  useEffect(() => {
    if (!cell || !!inlineEditorValue) {
      return
    }

    const { name } = cell

    setInlineEditorValue(name || "")

    if (cell && inlineEditorInputRef.current) {
      inlineEditorInputRef.current.focus()
    }
  }, [cell])

  const [isFocused, setFocused] = useState(false)

  return (
    <div
      className="formula-editor"
      onFocus={() => {
        setFocused(true)
        confirmChangeTimeoutRef.current &&
          window.clearTimeout(confirmChangeTimeoutRef.current)
      }}
      onBlur={() => {
        // if (formulaHelperType) {
        //   confirmChangeTimeoutRef.current = window.setTimeout(() => {
        //     if (cell.name !== inlineEditorValue) {
        //       onConfirm(inlineEditorValue)
        //     }
        //   }, 200)
        // } else {
        //   if (cell.name !== inlineEditorValue) {
        //     onConfirm(inlineEditorValue)
        //   }
        // }

        if (cell.name !== inlineEditorValue) {
          onConfirm(inlineEditorValue, cell)
        }

        if (isFocused) {
          onClose && onClose()
        }
      }}
    >
      <TextareaAutosize
        id="test"
        className="text-field cell-input"
        value={inlineEditorValue}
        onChange={handleInlineEditorValueChange}
        // onKeyUp={handleInlineEditorCursorChange}
        // onClick={handleInlineEditorCursorChange}
        ref={inlineEditorInputRef}
        style={{ caretColor: editingEnabled ? "#000" : "transparent" }}
      />

      {/* {formulaHelperType === "operator" && (
        <div className="formula-helper field-operator-helper">
          {formulaOperators.map((item) => (
            <div
              className="select-option"
              key={`select-option-${cell.operator || ""}-${item.label}`}
            >
              <div onClick={() => handleSelectOperator(item.value)}>
                <span className="operator-name">{item.label}</span>
                <span className="operator-separator">:</span>
                <span className="operator-hint">{item.hint}</span>
              </div>
            </div>
          ))}
        </div>
      )}

      {formulaHelperType === "name" && (
        <div className="formula-helper field-name-helper">
          {sheetFields.map((item) => (
            <div
              className="select-option"
              key={`select-option-${cell.operator || ""}-${item.name}`}
            >
              <div onClick={() => handleSelectFieldName(item.name!)}>
                <span className="field-name">{item.name}</span>
              </div>
            </div>
          ))}
        </div>
      )}

      {formulaHelperType === "value" && (
        <div
          className="formula-helper field-value-helper"
          style={{
            left: `=${formulaOperator}(${formulaCellName},`.length * 7.5,
          }}
        >
          {sheetFields.map((item) => (
            <div
              className="select-option"
              key={`select-option-${cell.operator || ""}-${item.name}`}
            >
              <div onClick={() => handleSelectFieldValue(item.name!)}>
                <span className="field-name">{item.name}</span>
              </div>
            </div>
          ))}
        </div>
      )} */}
    </div>
  )
}
