import { Node, Edge, Connection } from "reactflow"
import { UISheet, UILogic, UIEdge } from "../types/UITypes"
import { createEdge } from "./canvasHelpers"

export const addUIEdgeToLogic = (
  nodes: Node<UISheet | UILogic>[],
  edges: Edge<UIEdge>[],
  connection: Connection,
  sourceSheetId: string | null,
  sourceRowIndex: string | null,
  destCellIncomingEdge: Edge<UIEdge>
) => {
  const source = connection.source!
  const newEdgeTargetLogicId = destCellIncomingEdge.data?.sourceLogicId!

  const newEdge = createEdge(
    source,
    `logic-${newEdgeTargetLogicId}`,
    sourceSheetId,
    null,
    parseInt(sourceRowIndex!),
    null,
    null,
    newEdgeTargetLogicId,
    `edge-source-sheet-${sourceSheetId}-row-${sourceRowIndex}-node-${
      ((
        nodes.find(
          ({ id, type }) => id === source && type === "sheet"
        ) as Node<UISheet>
      )?.data.edgeHandles?.length || 0) + 1
    }`,
    `edge-target-logic-${newEdgeTargetLogicId}-node-${
      ((
        nodes.find(
          ({ data, type }) =>
            data.id === newEdgeTargetLogicId && type === "logic"
        ) as Node<UILogic>
      )?.data.edgeHandles?.length || 0) + 1
    }`,
    destCellIncomingEdge.data?.appearanceType || 0
  )

  const updatedEdges = [...edges, newEdge]

  const updatedNodes = nodes.map((node) => {
    if (node.id !== source && node.data.id !== newEdgeTargetLogicId) {
      return node
    }

    if (node.id === source) {
      return {
        ...node,
        data: {
          ...node.data,
          edgeHandles: [
            ...(node.data.edgeHandles || []),
            {
              id: `source-sheet-${sourceSheetId}-row-${sourceRowIndex}-node-${
                (node.data.edgeHandles || []).length + 1
              }`,
              rowIndex: parseInt(sourceRowIndex!),
              nodeType: "source",
            },
          ],
        },
      }
    }

    return {
      ...node,
      data: {
        ...node.data,
        edgeHandles: [
          ...((node.data as UILogic).edgeHandles || []),
          {
            id: `target-logic-${newEdgeTargetLogicId}-node-${
              ((node.data as UILogic).edgeHandles || []).length + 1
            }`,
            nodeType: "target",
          },
        ],
      },
    }
  })

  return { updatedNodes, updatedEdges, newEdge }
}
