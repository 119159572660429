import { useEffect, useState } from "react"
import { supabase } from "../database/SupabaseConnector"
import { Link } from "react-router-dom"

export const ResetPasswordPage = () => {
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false)

  const handleConfirmPassword = async () => {
    const { data, error } = await supabase.auth.updateUser({ password })

    if (!data.user && error?.message) {
      setError(error?.message)
    } else {
      setError("")
    }

    if (data.user) {
      setPasswordChangeSuccess(true)
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 200,
        fontSize: "14px",
        color: "#808080",
        flexDirection: "column",
      }}
    >
      {!passwordChangeSuccess && (
        <div style={{ maxWidth: 400 }}>
          <label>New Password</label>
          <input
            type="password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            style={{
              marginTop: 8,
              width: "100%",
              boxSizing: "border-box",
              borderRadius: 4,
              border: "1px solid #d3d3d3",
              backgroundColor: "rgb(232, 240, 254)",
              boxShadow: "none",
              padding: "10px 15px",
            }}
          />
          <div style={{ color: "#f00" }}>{error}</div>

          <button
            style={{
              width: "100%",
              padding: "10px 15px",
              background: "rgb(63, 207, 142)",
              marginTop: 20,
              border: "1px solid #d3d3d3",
              borderRadius: 4,
              color: "#fff",
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={handleConfirmPassword}
          >
            Confirm
          </button>
        </div>
      )}
      {passwordChangeSuccess && (
        <div
          style={{
            width: 300,
            border: "1px solid #d3d3d3",
            borderRadius: 6,
            padding: "20px 20px 30px 20px",
          }}
        >
          <div style={{ marginBottom: 20, fontSize: 16 }}>
            Password reset successful
          </div>
          <Link
            to={`/`}
            style={{
              width: "100%",
              padding: "8px 12px",
              background: "rgb(63, 207, 142)",
              marginTop: 20,
              marginBottom: 20,
              border: "1px solid #d3d3d3",
              borderRadius: 4,
              color: "#fff",
              fontSize: "15px",
            }}
          >
            Home
          </Link>
        </div>
      )}
    </div>
  )
}

export default ResetPasswordPage
