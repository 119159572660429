import { Handle, Position } from "reactflow"
import { UICell, UINode, UISheet } from "../../types/UITypes"
import { MouseEvent } from "react"
import "./SheetRenderer.css"
import CellContextMenu from "./CellContextMenu"

type RowIndexRendererProps = {
  cell: UICell
  connectionModeEnabled: boolean
  onCellMouseUp: (event: MouseEvent, rowIndex: number) => void
  getNodeTopPos: (
    cell: UICell,
    i: number,
    nodeType: string,
    edgeHandles: UINode[]
  ) => string
  onCellContextMenu: (cellId: string, target: "rowIndex" | "cell") => void
  cellWithContextMenu?: { cellId: string; target: "rowIndex" | "cell" }
  renderHandles?: boolean
  sheet: UISheet
}

export const RowIndexRenderer = ({
  cell,
  getNodeTopPos,
  onCellMouseUp,
  sheet,
  connectionModeEnabled,
  onCellContextMenu,
  cellWithContextMenu,
  renderHandles = true,
}: RowIndexRendererProps) => {
  if (!cell) {
    return null
  }

  return (
    <div
      key={cell.id}
      id={`cell-${cell.id}`}
      className="cell header-cell"
      style={{ width: 30, maxWidth: 30 }}
      onMouseUp={(event: MouseEvent) => onCellMouseUp(event, cell.rowIndex)}
      onContextMenu={(event) => {
        event.preventDefault()
        event.stopPropagation()

        onCellContextMenu(cell.id, "rowIndex")
      }}
    >
      <div className="row-number-cell">{cell.rowIndex || 1}</div>
      {sheet.edgeHandles
        ?.filter(
          (edgeHandle) =>
            edgeHandle.nodeType === "target" &&
            edgeHandle.rowIndex === cell.rowIndex
        )
        .map((edgeHandle, i) => (
          <Handle
            key={`edge-${edgeHandle.id}`}
            id={`edge-${edgeHandle.id}`}
            type="target"
            position={Position.Left}
            style={{
              top: getNodeTopPos(
                cell,
                i,
                edgeHandle.nodeType,
                sheet.edgeHandles
              ),
              transform: "translateY(-50%)",
              bottom: "auto",
              background: "none",
              border: "none",
            }}
          />
        ))}
      {renderHandles && (
        <Handle
          type="target"
          position={Position.Left}
          id={`target-sheet-${cell.sheetId}-row-${cell.rowIndex}`}
          style={{
            visibility: connectionModeEnabled ? "visible" : "hidden",
          }}
          className="target-handle"
          isConnectable
        />
      )}
      <CellContextMenu
        cell={cell}
        cellWithContextMenu={cellWithContextMenu}
        sheet={sheet}
        target="rowIndex"
      />
    </div>
  )
}
export default RowIndexRenderer
