import { Position, getSmoothStepPath } from "reactflow"
import { getUnitVector } from "./common"

export const EDGE_COLORS = [
  "#4b91ec",
  "#ca84e2",
  "#ff7cb1",
  "#ffc547",
  "#2ECC71",
  "#e7364b",
  "#00aff0",
  "#D4A4FF",
]

export const getConnectToCellPath = (
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number
) => {
  const labelX = (targetX - sourceX) / 2
  const labelY = (targetY - sourceY) / 2

  if (Math.abs(sourceY - targetY) <= 1) {
    return {
      edgePath: `M${sourceX},${sourceY} L${targetX},${targetY}`,
      labelX: sourceX + labelX,
      labelY: sourceY + labelY,
    }
  }

  let edgePath = ""

  const intersectionX = sourceX + (targetX - sourceX) / 2
  const intersectionY = sourceY + (targetY - sourceY) / 2

  const angle = Math.atan(
    Math.abs((intersectionY - sourceY) / (intersectionX - (sourceX + 30)))
  )

  if (Math.abs(angle) > 70 * (Math.PI / 180) || targetX < sourceX) {
    const [edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
      borderRadius: 20,
    })
    return {
      edgePath,
      labelX,
      labelY,
    }
  }

  const cathetusLength =
    Math.abs(intersectionY - sourceY) / Math.tan(Math.max(Math.PI / 4, angle))

  edgePath += `M${sourceX},${sourceY}`

  const corner1X = intersectionX - cathetusLength
  const corner1Y = sourceY
  const corner2X = intersectionX + cathetusLength
  const corner2Y = targetY

  const [corner1UnitVectorX, corner1UnitVectorY] = getUnitVector(
    corner2X - corner1X,
    corner2Y - corner1Y
  )

  const cornerSize = Math.min(10, Math.abs((corner2Y - corner1Y) / 2))

  edgePath += ` L${intersectionX - cathetusLength - cornerSize},${sourceY}`

  edgePath += ` Q${corner1X},${corner1Y} ${
    corner1X + corner1UnitVectorX * cornerSize
  },${corner1Y + corner1UnitVectorY * cornerSize}`

  edgePath += ` L${corner2X - corner1UnitVectorX * cornerSize},${
    corner2Y - corner1UnitVectorY * cornerSize
  }`

  edgePath += ` Q${corner2X},${corner2Y} ${corner2X + cornerSize},${corner2Y}`

  edgePath += ` L${targetX},${targetY}`

  return { edgePath, labelX: sourceX + labelX, labelY: sourceY + labelY }
}

export const getConnectToLogicPath = (
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number
) => {
  const halfWidth = (targetX - sourceX) / 2
  let labelX = 0
  let labelY = 0

  if (Math.abs(sourceY - targetY) <= 1) {
    return {
      edgePath: `M${sourceX},${sourceY} L${targetX},${targetY}`,
      labelX: sourceX + halfWidth,
      labelY: sourceY,
    }
  }

  let edgePath = ""

  edgePath += `M${sourceX},${sourceY}`

  const cathetusLength = Math.abs(targetY - sourceY) / Math.tan(Math.PI / 4)

  const corner1X = targetX - cathetusLength
  const corner1Y = sourceY

  const [corner1UnitVectorX, corner1UnitVectorY] = getUnitVector(
    targetX - corner1X,
    targetY - corner1Y
  )

  const cornerSize = Math.min(10, Math.abs((targetY - corner1Y) / 2))

  edgePath += ` L${corner1X - cornerSize},${corner1Y}`

  edgePath += ` Q${corner1X},${corner1Y} ${
    corner1X + corner1UnitVectorX * cornerSize
  },${corner1Y + corner1UnitVectorY * cornerSize}`

  edgePath += ` L${targetX},${targetY}`

  if (corner1X - sourceX > 80) {
    labelX = sourceX + (corner1X - sourceX) / 2
    labelY = sourceY
  } else {
    labelX = sourceX + halfWidth

    if (corner1X >= sourceX + halfWidth) {
      labelY = sourceY
    } else {
      const dY =
        (halfWidth - (corner1X - sourceX)) *
        Math.tan(Math.PI / 4) *
        (corner1Y < targetY ? 1 : -1)
      labelY = sourceY + dY
    }
  }

  return { edgePath, labelX, labelY }
}

export const getColorFromAppearanceType = (appearanceType = 0) => {
  if (appearanceType >= EDGE_COLORS.length) {
    return EDGE_COLORS[appearanceType % EDGE_COLORS.length]
  }

  return EDGE_COLORS[appearanceType]
}
