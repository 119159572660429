import { Connection, Edge, Node } from "reactflow"
import { UILogic, UISheet } from "../types/UITypes"

export const areCellsConnectedThroughLogic = (
  nodes: Node<UISheet | UILogic, string | undefined>[],
  edges: Edge[],
  connection: Connection,
  sourceSheetId: string,
  sourceRowIndex: string,
  targetSheetId: string,
  targetRowIndex: string
) => {
  const { source, target, sourceHandle, targetHandle } = connection

  const sourceSheet = nodes.find(({ id }) => id === source)
  const targetSheet = nodes.find(({ id }) => id === target)

  if (!sourceSheetId || !sourceRowIndex || !targetSheetId || !targetRowIndex) {
    return false
  }

  const sourceCellOutgoingEdges = edges.filter(
    (edge) =>
      edge.sourceHandle?.replace(/edge-/, "").replace(/-node-.*/, "") ===
      sourceHandle
  )

  const targetCellIncomingEdge = edges.find(
    (edge) =>
      edge.targetHandle?.replace(/edge-/, "").replace(/-node-.*/, "") ===
      targetHandle
  )

  if (sourceCellOutgoingEdges?.length === 0 || !targetCellIncomingEdge) {
    return false
  }

  const logicConnectedToTargetCellId = targetCellIncomingEdge.sourceHandle
    ?.replace(/edge-source-/, "")
    .replace(/-node-.*/, "")

  if (!logicConnectedToTargetCellId) {
    return false
  }

  const isSourceLogicConnectedToTargetCellThroughLogic =
    sourceCellOutgoingEdges.find(
      ({ targetHandle }) =>
        targetHandle?.replace(/edge-target-/, "").replace(/-node-.*/, "") ===
        logicConnectedToTargetCellId
    )

  return isSourceLogicConnectedToTargetCellThroughLogic
}
