import { PostgrestError, createClient } from "@supabase/supabase-js"
import { Database } from "../types/SupabaseTypes"
import { Edge, Node, XYPosition } from "reactflow"
import {
  UISheet,
  UICell,
  UILogic,
  UILogicType,
  UIEdge,
  SheetField,
  UIColumnLabel,
  UIRowLabel,
} from "../types/UITypes"

import {
  DatabaseCanvas,
  DatabaseCell,
  DatabaseColumnLabel,
  DatabaseEdge,
  DatabaseLogic,
  DatabaseSheet,
} from "../types/SupabaseTypesHelper"
import {
  createUIEdgesData,
  createUILogics,
  createUISheetData,
} from "./converters"
import { getCellValueType } from "../utils/common"
import {
  comparisonFormulaRegExp,
  comparisonRegExp,
  comparisonRegExpStr,
  comparisonShortFormRegExp,
  logicRegExp,
  nestedLogicRegExp,
  operators,
} from "../utils/constants"

export const supabase = createClient<Database>(
  process.env.REACT_APP_SUPABASE_URL!,
  process.env.REACT_APP_SUPABASE_ANON_KEY!
)

export type DatabaseError = {
  error?: PostgrestError | PostgrestError[] | null
  errorMessage?: string
}

export type AddLogicConnectionResponse = {
  logic: DatabaseLogic
  edges: DatabaseEdge[]
}

export type UpdateSheetFieldsProps = {
  newSheetFields?: SheetField[]
  editedSheetFields?: SheetField[]
}

export type ApiSheet = {
  id: string
  name: string | null
}

export type ApiCell = {
  id: string
  name: string
  operator?: string | null
  value?: string | null
  cellType: number
  cellLogicType?: string
  conditions?: ApiCell[]
  prev?: ApiCell | ApiLogic
  apiSheetId?: string
  rowIndex: number
  columnIndex: number
}

export type ApiLogic = {
  id: string
  logicType: string
  prev: Array<ApiCell | ApiLogic>
}

export type ApiHelperData = {
  apiSheets: UISheet[]
  cellsBySheetId: Record<string, UICell[]>
  outgoingEdgesByCellId: Record<string, UIEdge[]>
  incomingEdgesByCellId: Record<string, UIEdge>
  outgoingEdgesByLogicId: Record<string, UIEdge[]>
  incomingEdgesByLogicId: Record<string, UIEdge[]>
  cellsById: Record<string, UICell>
  logicsById: Record<string, UILogic>
  cells: UICell[]
  columnLabelsBySheetId: Record<string, UIColumnLabel[]>
  sheetFields: SheetField[]
}

export type PublishedApi = {
  sheetId: string
  sheetName?: string
  cells: ApiCell[]
  apiRows: ApiCell[][]
}

export const getUIEdgeData = (edgeData: DatabaseEdge) => ({
  id: edgeData.id,
  appearanceType: edgeData.appearance_type || 0,
  sourceSheetId: edgeData.source_sheet_id,
  sourceRowIndex: edgeData.source_row_index,
  sourceLogicId: edgeData.source_logic_id,
  targetSheetId: edgeData.dest_sheet_id,
  targetRowIndex: edgeData.dest_row_index,
  targetLogicId: edgeData.dest_logic_id,
})

const getDataSheetCellAlias = (name: string | undefined | null) => {
  const [_, alias] = name?.split(":") || []

  return alias
}

const getMappedApiCellName = (cellId: string, apiHelperData: ApiHelperData) => {
  const cellData = apiHelperData.cellsById[cellId]

  const mappedName1 = apiHelperData?.sheetFields.find(
    (cell) => getDataSheetCellAlias(cell.name) === cellData.name
  )?.name

  // Return api param name based on cell (friendly) name
  if (mappedName1) {
    return mappedName1.split(":")[0]
  }

  const columnLabel = apiHelperData.columnLabelsBySheetId[
    cellData.sheetId
  ]?.find(
    (columnLabel) => columnLabel.columnIndex === cellData.columnIndex
  )?.text

  const mappedName2 = apiHelperData?.sheetFields.find(
    (cell) => getDataSheetCellAlias(cell.name) === columnLabel
  )?.name

  // Return api param name based on column label
  return (mappedName2?.split(":") || [])[0] || columnLabel
}

const conditionToApiCell = (
  name: string,
  mathOperator: string,
  value: string,
  cellData: UICell,
  apiHelperData: ApiHelperData
): ApiCell | null => {
  let cellName: string | undefined = name

  if (!cellName) {
    cellName = apiHelperData?.columnLabelsBySheetId[cellData.sheetId].find(
      (columnLabel) => columnLabel.columnIndex === cellData.columnIndex
    )?.text
  }

  if (cellName) {
    cellName =
      apiHelperData?.sheetFields.find(
        (cell) => getDataSheetCellAlias(cell.name) === name
      )?.name || cellName
  }

  const apiCellOperator = operators.find(
    (operator) => operator.label === mathOperator
  )?.value!

  if (!cellName) {
    return null
  }

  return {
    id: "",
    name: cellName,
    operator: apiCellOperator,
    value,
    cellType: 0,
    rowIndex: cellData.rowIndex,
    columnIndex: cellData.columnIndex,
  }
}

const getApiCell = (
  cellData: UICell,
  apiHelperData: ApiHelperData,
  isApiSheetCell: boolean
): ApiCell => {
  // const formulaString = cellData?.name?.replaceAll(" ", "")
  const formulaString = cellData?.name
  const comparisonFormulaRegExpMatch = formulaString?.match(
    comparisonFormulaRegExp
  )
  const logicRegExpMatch = formulaString?.match(logicRegExp)
  const comparisonShortFormRegExpMatch = formulaString?.match(
    comparisonShortFormRegExp
  )
  const cellId = cellData.id

  let apiCellName: string | undefined | null
  let apiCellOperator: string | undefined | null
  let apiCellValue: string | undefined | null

  let cellLogicType: string | undefined
  let cellConditions: ApiCell[] | undefined

  let isLogicExpression = false

  if (logicRegExpMatch) {
    cellLogicType = logicRegExpMatch[2]
    // remove opening and closing bracket
    const logicExpressionsStr = logicRegExpMatch[3]
      .replace("(", "")
      .slice(0, -1)

    const logicExpressions: string[] = []

    const nestedLogicStrings = logicExpressionsStr.split(";")

    const nestedLogics: UICell[] = []

    nestedLogicStrings.forEach((nestedLogicString) => {
      if (nestedLogicString.match(nestedLogicRegExp)) {
        nestedLogics.push({
          ...cellData,
          name: `=${nestedLogicString}`,
        })
      }
    })

    if (nestedLogicStrings.length === nestedLogics.length) {
      const conditions = nestedLogics.map((nestedLogic) =>
        getApiCell(nestedLogic, apiHelperData, isApiSheetCell)
      )

      return {
        id: cellData.id,
        name: apiCellName!,
        operator: apiCellOperator,
        value: apiCellValue,
        cellType: getCellValueType(
          apiCellValue,
          apiHelperData.cells,
          apiCellName
        ),
        cellLogicType,
        conditions,
        rowIndex: cellData.rowIndex,
        columnIndex: cellData.columnIndex,
      }
    }

    let i = 0
    while (i < logicExpressionsStr.length) {
      let quoteStr = null

      if (logicExpressionsStr.indexOf('"', i) >= 0) {
        i = logicExpressionsStr.indexOf('"', i)
        quoteStr = '"'
      } else if (logicExpressionsStr.indexOf("'", i) >= 0) {
        i = logicExpressionsStr.indexOf("'", i)
        quoteStr = "'"
      }

      const closingQuoteIndex =
        quoteStr && logicExpressionsStr.indexOf(quoteStr, i + 1)

      if (quoteStr && closingQuoteIndex) {
        logicExpressions.push(
          logicExpressionsStr.substring(i + 1, closingQuoteIndex)
        )

        // Move the iteration cursor beyond the closing quote
        i = closingQuoteIndex + 1

        if (i < 0) {
          i = logicExpressionsStr.length
        }
      } else if (i < logicExpressionsStr.length) {
        let substrEnd = logicExpressionsStr.indexOf(",", i)

        if (substrEnd === -1) {
          substrEnd = logicExpressionsStr.length
        }

        const logicExpression = logicExpressionsStr.substring(i, substrEnd)

        logicExpressions.push(logicExpression)

        // Move the iteration cursor beyond the ',' character
        i = substrEnd + 1
      }
    }

    cellConditions = []

    logicExpressions.forEach((logicExpression) => {
      const comparisonRegExpMatch = logicExpression.match(comparisonRegExp)

      if (comparisonRegExpMatch) {
        const apiCell = conditionToApiCell(
          comparisonRegExpMatch[1],
          comparisonRegExpMatch[2],
          comparisonRegExpMatch[3],
          cellData,
          apiHelperData
        )

        if (apiCell) {
          cellConditions!.push(apiCell)
          isLogicExpression = true
        }
      } else {
        // Handle case like: AND|OR("value 1", "value 2", "value 3")
        const apiCell = conditionToApiCell(
          "",
          "=",
          logicExpression,
          cellData,
          apiHelperData
        )

        if (apiCell) {
          cellConditions!.push(apiCell)
          isLogicExpression = true
        }
      }
    })
  }

  if (!isLogicExpression) {
    if (comparisonFormulaRegExpMatch) {
      apiCellName = comparisonFormulaRegExpMatch[2]?.trim()
      const mathOperator = comparisonFormulaRegExpMatch[3]?.trim()
      apiCellValue = comparisonFormulaRegExpMatch[4]?.trim()

      apiCellOperator = operators.find(
        (operator) => operator.label === mathOperator
      )?.value!

      if (!apiCellName && apiCellOperator && apiCellValue) {
        // Use the mapped cell name by default if cell name is not specified
        apiCellName = getMappedApiCellName(cellId!, apiHelperData)
      }
    } else if (comparisonShortFormRegExpMatch) {
      const mathOperator = comparisonShortFormRegExpMatch[1]?.trim()
      apiCellValue = comparisonShortFormRegExpMatch[2]?.trim()

      apiCellOperator = operators.find(
        (operator) => operator.label === mathOperator
      )?.value!

      if (!apiCellName && apiCellOperator && apiCellValue) {
        // Use the mapped cell name by default if cell name is not specified
        apiCellName = getMappedApiCellName(cellId!, apiHelperData)
      }
    } else if (cellData.name) {
      // Use the "=" operator by default if only the mapped cell name and cell name is specified.
      // Also, use cell name as value and sheet name as a cell name in this case.
      apiCellOperator = "eq"
      apiCellValue = cellData.name
      apiCellName = getMappedApiCellName(cellId!, apiHelperData)
    } else if (!apiCellName && !apiCellOperator && !apiCellValue) {
      apiCellName = ""
      apiCellOperator = ""
      apiCellValue = ""
    }
  }

  if (isApiSheetCell) {
    apiHelperData.cells.forEach((cell) => {
      const [inputVarName, inputVarAlias] = cell.name?.split(":") || []

      if (inputVarName && inputVarAlias) {
        apiCellValue = apiCellValue?.replaceAll(inputVarAlias, inputVarName)
      }
    })
  }

  return {
    id: cellData.id,
    name: apiCellName!,
    operator: apiCellOperator,
    value: apiCellValue,
    cellType: getCellValueType(apiCellValue, apiHelperData.cells, apiCellName),
    cellLogicType,
    conditions: cellConditions,
    rowIndex: cellData.rowIndex,
    columnIndex: cellData.columnIndex,
  }
}

const addApiEntry = ({
  cellId,
  sheetId,
  logicId,
  apiHelperData,
  isApiSheetCell,
}: {
  cellId?: string
  sheetId: string
  logicId?: string
  apiHelperData: ApiHelperData
  isApiSheetCell?: boolean
}): ApiCell | ApiLogic => {
  if (cellId) {
    const cellData = apiHelperData.cellsById[cellId]
    const apiCell = getApiCell(cellData, apiHelperData, !!isApiSheetCell)

    if (cellData.columnIndex && cellData.columnIndex > 1) {
      const cellsInCurrentRow = apiHelperData.cellsBySheetId[
        cellData.sheetId
      ]?.filter((cell) => cell.rowIndex === cellData.rowIndex)

      cellsInCurrentRow?.sort((a, b) =>
        a.columnIndex && b.columnIndex ? a.columnIndex - b.columnIndex : 0
      )

      const lastCellInRow = cellsInCurrentRow[cellsInCurrentRow.length - 1]

      if (lastCellInRow.columnIndex === cellData.columnIndex) {
        const apiLogic: ApiLogic = {
          id: "",
          logicType: UILogicType.AND,
          prev: [],
        }

        const prevCells = [
          ...cellsInCurrentRow.slice(0, cellsInCurrentRow.length - 1).map(
            (cell) =>
              addApiEntry({
                cellId: cell.id,
                sheetId,
                apiHelperData,
              }) as ApiCell
          ),
          apiCell,
        ]

        return {
          ...apiLogic,
          prev: prevCells.map((cell) => ({
            ...cell,
            prev: (prevCells[0] as ApiCell).prev,
          })),
        } as ApiLogic
      }
    }

    const incomingEdge = apiHelperData.incomingEdgesByCellId[cellId]

    if (incomingEdge) {
      const sourceSheetId = incomingEdge.sourceSheetId
      const sourceRowIndex = incomingEdge.sourceRowIndex
      let sourceCellId

      if (sourceSheetId && sourceRowIndex) {
        const cellsRow = apiHelperData.cellsBySheetId[sourceSheetId]
          .filter((cell) => cell.rowIndex === sourceRowIndex)
          ?.sort((a, b) => a.columnIndex - b.columnIndex)

        sourceCellId = cellsRow[cellsRow.length - 1].id
      }

      const sourceLogicId = incomingEdge.sourceLogicId

      if (sourceCellId) {
        return {
          ...apiCell,
          prev: addApiEntry({
            cellId: sourceCellId,
            sheetId,
            apiHelperData,
          }),
        }
      }

      if (sourceLogicId) {
        const prevLogic = addApiEntry({
          logicId: sourceLogicId,
          sheetId,
          apiHelperData,
        }) as ApiLogic

        return {
          ...apiCell,
          prev: prevLogic,
        }
      }
    }

    return apiCell
  } else {
    const logicData = logicId ? apiHelperData.logicsById[logicId!] : undefined

    const apiLogic: ApiLogic = {
      id: logicData?.id!,
      logicType: logicData?.logicType!,
      prev: [],
    }

    const incomingEdges = apiHelperData.incomingEdgesByLogicId[apiLogic.id]

    const prevCells = incomingEdges?.map((edge) => {
      const sourceSheetId = edge.sourceSheetId!
      const sourceRowIndex = edge.sourceRowIndex!
      let sourceCellId

      const cellsRow = apiHelperData.cellsBySheetId[sourceSheetId]
        .filter((cell) => cell.rowIndex === sourceRowIndex)
        ?.sort((a, b) => a.columnIndex - b.columnIndex)

      sourceCellId = cellsRow[cellsRow.length - 1].id

      return addApiEntry({
        cellId: sourceCellId,
        sheetId,
        apiHelperData,
      }) as ApiCell
    })

    return { ...apiLogic, prev: prevCells } as ApiLogic
  }
}

const getSheetApi = (sheet: ApiSheet, apiHelperData: ApiHelperData) => {
  // Leftmost cells of the API sheet.
  const apiSheetLeftCells = apiHelperData.cellsBySheetId[sheet.id]
    .filter((cell) => !!apiHelperData.incomingEdgesByCellId[cell.id])
    .sort((a, b) => (a.rowIndex ?? 0) - (b.rowIndex ?? 0))

  // All cells in the row where leftmost cells have incoming connection
  const result = {
    sheetId: sheet.id,
    sheetName: sheet.name || "",
    cells: apiSheetLeftCells.map((cell) =>
      addApiEntry({
        cellId: cell.id,
        sheetId: sheet.id,
        apiHelperData,
        isApiSheetCell: true,
      })
    ) as ApiCell[],
    apiRows: [] as ApiCell[][],
  }

  result.cells.forEach((cell) => {
    result.apiRows.push(
      apiHelperData.cellsBySheetId[sheet.id]
        ?.filter((rowCell) => rowCell.rowIndex === cell.rowIndex)
        .map((cell) => getApiCell(cell, apiHelperData, true))
    )
  })

  return result
}

export class SupabaseConnector {
  canvasId?: string

  constructor(canvasId?: string) {
    this.canvasId = canvasId
  }

  setCanvasId(canvasId?: string) {
    this.canvasId = canvasId
  }

  fetchData = async () => {
    const { data: sheetsData } = await supabase
      .from("sheet")
      .select()
      .eq("canvas_id", this.canvasId)

    const { data: cellsData } = await supabase
      .from("cell")
      .select()
      .eq("canvas_id", this.canvasId)

    const { data: edgesData } = await supabase
      .from("edge")
      .select()
      .eq("canvas_id", this.canvasId)

    const { data: logicsData } = await supabase
      .from("logic")
      .select()
      .eq("canvas_id", this.canvasId)

    const { data: logicTypesData } = await supabase.from("logic_type").select()

    const { data: columnLabels } = await supabase
      .from("column_label")
      .select()
      .eq("canvas_id", this.canvasId)

    const { data: rowLabels } = await supabase
      .from("row_label")
      .select()
      .eq("canvas_id", this.canvasId)

    const { data: columnLabelLabelsData } = await supabase
      .from("column_label_label")
      .select()
      .eq("canvas_id", this.canvasId)

    const { data: rowLabelLabelsData } = await supabase
      .from("row_label_label")
      .select()
      .eq("canvas_id", this.canvasId)

    const logics: Node<UILogic>[] = createUILogics(
      logicsData,
      logicTypesData,
      edgesData
    )

    const sheets: Node<UISheet>[] = (sheetsData || []).map((sheetData) =>
      createUISheetData(
        sheetData,
        cellsData,
        edgesData,
        columnLabels,
        rowLabels,
        columnLabelLabelsData,
        rowLabelLabelsData
      )
    )

    let edges: Edge<UIEdge>[] = []

    if (edgesData) {
      edges = createUIEdgesData(edgesData, sheetsData, logicsData)
    }

    const canvasSheets = sheets.filter((sheet) => !sheet.data.isDataSheet)
    const dataSheet = sheets.find((sheet) => sheet.data.isDataSheet)

    return { sheets: canvasSheets, logics, edges, dataSheet }
  }

  updateCellValue = async (
    updatedCellValue: string,
    cellId: string
  ): Promise<DatabaseCell | undefined> => {
    const { data, error } = await supabase
      .from("cell")
      .update({
        name: updatedCellValue,
      })
      .eq("id", cellId)
      .select()

    if (error) {
      return Promise.resolve(undefined)
    }

    return data[0]
  }

  updateCell = async (cell: UICell): Promise<DatabaseCell | undefined> => {
    const { data, error } = await supabase
      .from("cell")
      .update({
        name: cell.name?.trim(),
        column_index: cell.columnIndex || 1,
        row_index: cell.rowIndex || 1,
        // cell_type: cell.valueType || null,
      })
      .eq("id", cell.id)
      .select()

    if (error) {
      return Promise.resolve(undefined)
    }

    return data[0]
  }

  updateColumnLabel = async (
    columnLabel: UIColumnLabel
  ): Promise<DatabaseColumnLabel | undefined> => {
    const { data, error } = await supabase
      .from("column_label")
      .update({
        text: columnLabel.text?.trim(),
        column_index: columnLabel.columnIndex || 1,
      })
      .eq("id", columnLabel.id)
      .select()

    if (error) {
      return Promise.resolve(undefined)
    }

    return data[0]
  }

  async updateSheetName(sheet: UISheet) {
    await supabase.from("sheet").update({ name: sheet.name }).eq("id", sheet.id)
  }

  async updateMatrixLabel(
    labelType: string,
    labelText: string,
    sheetId: string
  ) {
    const tableName =
      labelType === "columnLabelsLabel"
        ? "column_label_label"
        : "row_label_label"

    await supabase
      .from(tableName)
      .update({ text: labelText })
      .eq("sheet_id", sheetId)
  }

  async addLogicConnection(
    newLogic: Node<UILogic>,
    removedEdge: Edge<UIEdge>,
    newEdges: Edge<UIEdge>[]
  ): Promise<unknown> {
    try {
      await supabase.rpc("handle_create_logic", {
        new_logic: {
          id: newLogic.data.id,
          logic_type: newLogic.data.logicType,
          position_x: newLogic.position.x,
          position_y: newLogic.position.y,
          canvas_id: this.canvasId,
        },
        removed_edge_id: removedEdge.data?.id!,
        // @ts-ignore
        cell_to_logic_edges: newEdges
          .filter((edge) => edge.data?.sourceRowIndex)
          .map((edge) => ({
            id: edge.data?.id,
            source_row_index: edge.data?.sourceRowIndex ?? null,
            source_sheet_id: edge.data?.sourceSheetId ?? null,
            appearance_type: edge.data?.appearanceType,
            canvas_id: this.canvasId,
            dest_logic_id: newLogic.data.id,
          })),
        // @ts-ignore
        logic_to_cell_edges: newEdges
          .filter((edge) => edge.data?.targetRowIndex)
          .map((edge) => ({
            id: edge.data?.id,
            dest_row_index: edge.data?.targetRowIndex ?? null,
            dest_sheet_id: edge.data?.targetSheetId ?? null,
            appearance_type: edge.data?.appearanceType,
            canvas_id: this.canvasId,
            source_logic_id: newLogic.data.id,
          })),
      })
    } catch (error) {
      return {
        errorMessage: "Create edges error",
        error: error as PostgrestError,
      }
    }
  }

  async addEdge(newEdge: Edge<UIEdge>): Promise<unknown> {
    return supabase.from("edge").insert({
      id: `${newEdge.data?.id}`,
      source_row_index: newEdge.data?.sourceRowIndex || null,
      dest_row_index: newEdge.data?.targetRowIndex || null,
      source_sheet_id: newEdge.data?.sourceSheetId || null,
      dest_sheet_id: newEdge.data?.targetSheetId || null,
      source_logic_id: newEdge.data?.sourceLogicId || null,
      dest_logic_id: newEdge.data?.targetLogicId || null,
      appearance_type: newEdge.data?.appearanceType || 0,
      canvas_id: this.canvasId,
    })
  }

  updateEdges = (updatedEdges: Edge<UIEdge>[]) => {
    const promises = updatedEdges.map(async (edge) =>
      supabase
        .from("edge")
        .update({
          source_row_index: edge.data?.sourceRowIndex || null,
          dest_row_index: edge.data?.targetRowIndex || null,
          source_sheet_id: edge.data?.sourceSheetId || null,
          dest_sheet_id: edge.data?.targetSheetId || null,
          source_logic_id: edge.data?.sourceLogicId || null,
          dest_logic_id: edge.data?.targetLogicId || null,
          appearance_type: edge.data?.appearanceType || 0,
        })
        .eq("id", edge.data?.id)
        .select()
    )

    return promises
  }

  // TODO: see if this can be raplaced with utils/removeCells or some generic method that could be used in both plcaces.
  deleteCells = async (cellIds: string[]) => {
    if (!cellIds.length) {
      return Promise.resolve([])
    }

    const { data: deletedCellsData } = await supabase
      .from("cell")
      .delete()
      .in("id", cellIds)
      .select()

    if (!deletedCellsData?.length) {
      return Promise.resolve([])
    }

    const affectedSheetIds = new Set()

    deletedCellsData.forEach(({ sheet_id }) => affectedSheetIds.add(sheet_id))

    const updatedCellsData: DatabaseCell[] = []

    await Promise.all(
      Array.from(affectedSheetIds).map(async (sheetId) => {
        const { data: cellsData } = await supabase
          .from("cell")
          .select()
          .eq("sheet_id", sheetId)

        if (!cellsData?.length) {
          return Promise.resolve()
        }

        const cellsById: Record<string, DatabaseCell> = {}
        const sortedCellsData = cellsData.sort((a, b) => {
          let cellASortingKey = a.row_index || 0
          let cellBSortingKey = b.row_index || 0

          cellsById[a.id] = a
          cellsById[b.id] = b

          return cellASortingKey - cellBSortingKey
        })

        const cellsToUpdate: DatabaseCell[] = []
        sortedCellsData.forEach((cell, cellIndex) => {
          if (cell.row_index !== cellIndex + 1) {
            cellsToUpdate.push({ ...cell, row_index: cellIndex + 1 })
          }
        })

        if (!cellsToUpdate.length) {
          return Promise.resolve()
        }

        return await Promise.all(
          cellsToUpdate.map(async (cellToUpdate) => {
            const { data } = await supabase
              .from("cell")
              .update({
                row_index: cellToUpdate.row_index,
              })
              .eq("id", cellToUpdate.id)
              .select()

            if (data) {
              updatedCellsData.push(...data)
            }

            return data
          })
        )
      })
    )

    return updatedCellsData.filter((cell) => !!cell)
  }

  handleRemoveCells = async (
    edgeIds: string[],
    cellIds: string[],
    logicIds: string[],
    columnLabelIds: string[],
    rowLabelIds: string[],
    updatedEdges: Edge<UIEdge>[],
    updatedCells: UICell[],
    updatedColumnLabels: UIColumnLabel[],
    updatedRowLabels: UIRowLabel[],
    sheetId?: string
  ) => {
    await supabase.rpc("handle_remove_cells", {
      edge_ids: edgeIds,
      cell_ids: cellIds,
      logic_ids: logicIds,
      column_label_ids: columnLabelIds,
      row_label_ids: rowLabelIds,
      // @ts-ignore
      updated_edges: updatedEdges.map((uiEdge) => ({
        id: uiEdge.data?.id!,
        source_row_index: uiEdge.data?.sourceRowIndex || null,
        dest_row_index: uiEdge.data?.targetRowIndex || null,
        source_sheet_id: uiEdge.data?.sourceSheetId || null,
        dest_sheet_id: uiEdge.data?.targetSheetId || null,
        source_logic_id: uiEdge.data?.sourceLogicId ?? null,
        dest_logic_id: uiEdge.data?.targetLogicId ?? null,
      })),
      // @ts-ignore
      updated_cells: updatedCells.map((uiCell) => ({
        id: uiCell.id,
        column_index: uiCell.columnIndex,
        row_index: uiCell.rowIndex,
      })),
      // @ts-ignore
      updated_column_labels: updatedColumnLabels.map((uiColumnLabel) => ({
        id: uiColumnLabel.id,
        column_index: uiColumnLabel.columnIndex,
      })),
      // @ts-ignore
      updated_row_labels: updatedRowLabels.map((uiRowLabel) => ({
        id: uiRowLabel.id,
        row_index: uiRowLabel.rowIndex,
      })),
      sheet_id: sheetId,
      // check if we need canvas id here
      // canvas_id: this.canvasId,
    })
  }

  getCanvases = async (): Promise<DatabaseCanvas[]> => {
    const { data: canvases } = await supabase.from("canvas").select()

    if (!canvases) {
      return Promise.resolve([])
    }

    return canvases
  }

  getCanvas = async (
    canvasId: string
  ): Promise<DatabaseCanvas | undefined | null> => {
    const { data: canvases } = await supabase
      .from("canvas")
      .select()
      .eq("id", canvasId)

    return canvases?.length ? canvases[0] : null
  }

  createCanvas = async (): Promise<DatabaseCanvas | undefined> => {
    const { data: canvas } = await supabase.from("canvas").insert({
      name: "Canvas 1",
    })

    if (!canvas) {
      return Promise.resolve(undefined)
    }

    return canvas
  }

  getAPI = (
    uiNodes: Node<UISheet | UILogic>[],
    uiEdges: Edge<UIEdge>[],
    sheetFields: SheetField[]
  ): PublishedApi[] => {
    const sheets: UISheet[] = uiNodes
      .filter((uiNode) => uiNode.type === "sheet")
      .map((uiNode) => uiNode.data) as UISheet[]

    const logics: UILogic[] = uiNodes
      .filter((uiNode) => uiNode.type === "logic")
      .map((uiNode) => uiNode.data) as UILogic[]

    const edges: UIEdge[] = uiEdges.map((uiEdge) => uiEdge.data) as UIEdge[]

    const cells: UICell[] = []
    const sheetsById: Record<string, UISheet> = {}
    const cellsById: Record<string, UICell> = {}
    const cellsBySheetId: Record<string, UICell[]> = {}
    const columnLabelsBySheetId: Record<string, UIColumnLabel[]> = {}
    const logicsById: Record<string, UILogic> = {}

    sheets.forEach((sheet: UISheet) => {
      sheetsById[sheet.id] = sheet

      cellsBySheetId[sheet.id] = []
      sheet.cells.forEach((cell) => {
        cellsBySheetId[sheet.id].push(cell)
        cellsById[cell.id] = cell
        cells.push(cell)
      })

      columnLabelsBySheetId[sheet.id] = []
      sheet.columnLabels?.forEach((columnLabel) => {
        columnLabelsBySheetId[sheet.id].push(columnLabel)
      })
    })

    logics.forEach((logic: UILogic) => {
      logicsById[logic.id] = logic
    })

    const outgoingEdgesByCellId: Record<string, UIEdge[]> = {}
    const incomingEdgesByCellId: Record<string, UIEdge> = {}

    const outgoingEdgesByLogicId: Record<string, UIEdge[]> = {}
    const incomingEdgesByLogicId: Record<string, UIEdge[]> = {}

    edges?.forEach((edge) => {
      let sourceCellId

      if (edge.sourceSheetId && edge.sourceRowIndex) {
        const cellsRow = cellsBySheetId[edge.sourceSheetId]
          .filter((cell) => cell.rowIndex === edge.sourceRowIndex)
          ?.sort((a, b) => a.columnIndex - b.columnIndex)

        sourceCellId = cellsRow[cellsRow.length - 1].id
      }

      if (sourceCellId) {
        if (!outgoingEdgesByCellId[sourceCellId]) {
          outgoingEdgesByCellId[sourceCellId] = []
        }

        outgoingEdgesByCellId[sourceCellId].push(edge)
      }

      let targetCellId

      if (edge.targetSheetId && edge.targetRowIndex) {
        const cellsRow = cellsBySheetId[edge.targetSheetId]
          .filter((cell) => cell.rowIndex === edge.targetRowIndex)
          ?.sort((a, b) => a.columnIndex - b.columnIndex)

        targetCellId = cellsRow[0].id
      }

      if (targetCellId) {
        incomingEdgesByCellId[targetCellId] = edge
      }

      if (edge.sourceLogicId) {
        if (!outgoingEdgesByLogicId[edge.sourceLogicId]) {
          outgoingEdgesByCellId[edge.sourceLogicId] = []
        }

        outgoingEdgesByCellId[edge.sourceLogicId].push(edge)
      }

      if (edge.targetLogicId) {
        if (!incomingEdgesByLogicId[edge.targetLogicId]) {
          incomingEdgesByLogicId[edge.targetLogicId] = []
        }

        incomingEdgesByLogicId[edge.targetLogicId].push(edge)
      }
    })

    const apiSheets = sheets
      ?.filter((sheet) => {
        const cells = cellsBySheetId[sheet.id]

        return !!cells.some((cell) => incomingEdgesByCellId[cell.id])
      })
      .map(({ id, name }) => ({ id, name } as ApiSheet))

    const apiHelperData: ApiHelperData = {
      apiSheets: sheets,
      cellsBySheetId,
      outgoingEdgesByCellId,
      incomingEdgesByCellId,
      outgoingEdgesByLogicId,
      incomingEdgesByLogicId,
      cellsById,
      logicsById,
      cells,
      columnLabelsBySheetId,
      sheetFields,
    }

    const api = apiSheets?.map((sheet) => getSheetApi(sheet, apiHelperData))

    const matrices = uiNodes.filter((node) => node.type === "matrix") || []

    const matricesApi = matrices.map((matrix) => {
      const matrixData = matrix.data as UISheet
      const matrixCells: ApiCell[] = matrixData.cells
        .filter((matrixCell) => {
          const columnLabel =
            matrixData.columnLabels &&
            matrixData.columnLabels[matrixCell.columnIndex - 1]

          const rowLabel =
            matrixData.rowLabels &&
            matrixData.rowLabels[matrixCell.rowIndex - 1]

          const isColumnLabelLabelFormulaDefined =
            matrixData.columnLabelsLabel?.match(comparisonRegExpStr)
          const isColumnLabelFormulaDefined =
            columnLabel?.text?.match(comparisonRegExpStr)
          const isRowLabelLabelFormulaDefined =
            matrixData.rowLabelsLabel?.match(comparisonRegExpStr)
          const isRowLabelFormulaDefined =
            rowLabel?.text?.match(comparisonRegExpStr)

          if (
            (columnLabel || isColumnLabelLabelFormulaDefined) &&
            (rowLabel || isRowLabelLabelFormulaDefined) &&
            (matrixData.columnLabelsLabel || isColumnLabelFormulaDefined) &&
            (matrixData.rowLabelsLabel || isRowLabelFormulaDefined)
          ) {
            return true
          }

          return (
            columnLabel?.text?.match(comparisonRegExpStr) &&
            rowLabel?.text?.match(comparisonRegExpStr)
          )
        })
        .map((matrixCell) => {
          const columnLabel =
            matrixData.columnLabels &&
            matrixData.columnLabels[matrixCell.columnIndex - 1]

          const rowLabel =
            matrixData.rowLabels &&
            matrixData.rowLabels[matrixCell.rowIndex - 1]

          let xAxisConditionName
          let xAxisConditionOperator
          let xAxisConditionValue
          let yAxisConditionName
          let yAxisConditionValue
          let yAxisConditionOperator

          if (matrixData.rowLabelsLabel) {
            const rowLabelLabelFormulaMatch =
              matrixData.rowLabelsLabel.match(comparisonRegExpStr)!
            if (rowLabelLabelFormulaMatch) {
              yAxisConditionName = rowLabelLabelFormulaMatch[1]?.trim()
              const yAxisConditionOperatorLabel =
                rowLabelLabelFormulaMatch[2]?.trim()
              yAxisConditionValue = rowLabelLabelFormulaMatch[3]?.trim()
              yAxisConditionOperator = operators.find(
                (operator) => operator.label === yAxisConditionOperatorLabel
              )?.value!
            } else {
              yAxisConditionName = matrixData.rowLabelsLabel
              yAxisConditionOperator = "eq"
              yAxisConditionValue = rowLabel?.text
            }
          } else {
            const rowLabelFormulaMatch =
              rowLabel?.text?.match(comparisonRegExpStr)!
            yAxisConditionName = rowLabelFormulaMatch[1]?.trim()
            const yAxisConditionOperatorLabel = rowLabelFormulaMatch[2]?.trim()
            yAxisConditionValue = rowLabelFormulaMatch[3]?.trim()
            yAxisConditionOperator = operators.find(
              (operator) => operator.label === yAxisConditionOperatorLabel
            )?.value!
          }

          if (matrixData.columnLabelsLabel) {
            const columnLabelLabelFormulaMatch =
              matrixData.columnLabelsLabel.match(comparisonRegExpStr)!

            if (columnLabelLabelFormulaMatch) {
              xAxisConditionName = columnLabelLabelFormulaMatch[1]?.trim()
              const xAxisConditionOperatorLabel =
                columnLabelLabelFormulaMatch[2]?.trim()
              xAxisConditionValue = columnLabelLabelFormulaMatch[3]?.trim()
              xAxisConditionOperator = operators.find(
                (operator) => operator.label === xAxisConditionOperatorLabel
              )?.value!
            } else {
              xAxisConditionName = matrixData.columnLabelsLabel
              xAxisConditionOperator = "eq"
              xAxisConditionValue = columnLabel?.text
            }
          } else {
            const columnLabelFolrmulaMatch =
              columnLabel?.text?.match(comparisonRegExpStr)!
            xAxisConditionName = columnLabelFolrmulaMatch[1]?.trim()
            const xAxisConditionOperatorLabel =
              columnLabelFolrmulaMatch[2]?.trim()
            xAxisConditionValue = columnLabelFolrmulaMatch[3]?.trim()
            xAxisConditionOperator = operators.find(
              (operator) => operator.label === xAxisConditionOperatorLabel
            )?.value!
          }

          return {
            ...matrixCell,
            name: matrixData.name,
            operator: "eq",
            value: matrixCell.name,
            cellType: 2,
            prev: {
              logicType: UILogicType.AND,
              prev: [
                {
                  cellType: xAxisConditionOperator === "eq" ? 1 : 2,
                  value: xAxisConditionValue,
                  operator: xAxisConditionOperator,
                  name: xAxisConditionName,
                },
                {
                  cellType: yAxisConditionOperator === "eq" ? 1 : 2,
                  value: yAxisConditionValue,
                  operator: yAxisConditionOperator,
                  name: yAxisConditionName,
                },
              ],
            },
          }
        }) as unknown as ApiCell[]

      const apiRows = matrixCells.map((cell) => [cell])

      return {
        sheetId: matrix.data.id,
        sheetName: matrix.data.name,
        cells: matrixCells,
        apiRows: apiRows,
      }
    }) as unknown as PublishedApi[]

    return [...api, ...matricesApi]
  }

  updateNode = (node: Node) => {
    if (!node.type) {
      return
    }

    let nodeType = node.type

    if (nodeType === "matrix") {
      nodeType = "sheet"
    }

    return supabase
      .from(nodeType)
      .update({ position_x: node.position.x, position_y: node.position.y })
      .eq("id", node.data.id)
  }

  addSheet = async (
    centerCoords: XYPosition,
    sheetId: string,
    cells: UICell[],
    isComplex: boolean = false
  ): Promise<unknown> => {
    return supabase.rpc("handle_create_sheet", {
      sheet_id: sheetId,
      // ignore missing params like created_at, that automatically created by the DB
      // @ts-ignore
      cells: cells.map((cell) => ({
        id: cell.id,
        sheet_id: cell.sheetId,
        canvas_id: this.canvasId!,
        row_index: cell.rowIndex,
        column_index: cell.columnIndex,
      })),
      position_x: centerCoords.x,
      position_y: centerCoords.y,
      canvas_id: this.canvasId!,
      complex: isComplex,
    })
  }

  removeEdge = async (
    edgeId: string
  ): Promise<{ error: PostgrestError | null }> => {
    const { error } = await supabase.from("edge").delete().eq("id", edgeId)

    return { error }
  }

  removeLogic = async (
    logicId: string
  ): Promise<{ error: PostgrestError | null }> => {
    const { error } = await supabase.from("logic").delete().eq("id", logicId)

    return { error }
  }

  removeEdges = async (
    edgeIds: string[]
  ): Promise<{ error: PostgrestError | null }> => {
    const { error } = await supabase.from("edge").delete().in("id", edgeIds)

    return { error }
  }

  replaceLogicWithEdge = async (
    logicId: string,
    edgeToUpdate: Edge<any>,
    removedEdgeIds: string[]
  ): Promise<void> => {
    await supabase.rpc("replace_logic_with_edge", {
      logic_id: logicId,
      removed_edge_ids: removedEdgeIds,
      updated_edge_id: edgeToUpdate.data.id,
      updated_dest_sheet_id: edgeToUpdate.data.targetSheetId,
      updated_dest_row_index: edgeToUpdate.data.targetRowIndex,
    })
  }

  handleRemoveLogic = async (
    logicId: string,
    edgeIds: string[]
  ): Promise<void> => {
    await supabase.rpc("handle_remove_logic", {
      logic_id: logicId,
      edge_ids: edgeIds,
    })
  }

  createCells = async (cells: UICell[]): Promise<void> => {
    await supabase.rpc("create_cells", {
      // ignore missing params like created_at, that automatically created by the DB
      // @ts-ignore
      cells: cells.map((cell) => ({
        id: cell.id,
        sheet_id: cell.sheetId,
        canvas_id: this.canvasId!,
        row_index: cell.rowIndex,
        column_index: cell.columnIndex,
      })),
    })
  }

  updateCells = async (cells: UICell[]): Promise<void> => {
    await supabase.rpc("update_cells", {
      // ignore missing params like created_at, that automatically created by the DB
      // @ts-ignore
      cells: cells.map((cell) => ({
        id: cell.id,
        name: cell.name,
        row_index: cell.rowIndex,
        column_index: cell.columnIndex,
      })),
    })
  }

  shrinkSheet = async (
    sheetId: string,
    maxColumns: number | null,
    maxRows: number | null
  ) => {
    const deleteCells = supabase.from("cell").delete().eq("sheet_id", sheetId)

    if (maxColumns && maxRows) {
      await supabase
        .from("cell")
        .delete()
        .eq("sheet_id", sheetId)
        .gt("column_index", maxColumns)
      await supabase
        .from("cell")
        .delete()
        .eq("sheet_id", sheetId)
        .gt("row_index", maxRows)

      return Promise.resolve()
      // return await supabase.rpc("shrink_sheet", {
      //   sheet_id: sheetId,
      //   max_columns: maxColumns,
      //   max_rows: maxRows,
      // })
    } else if (maxColumns) {
      return await deleteCells.gt("column_index", maxColumns)
    } else if (maxRows) {
      return await deleteCells.gt("row_index", maxRows)
    }
  }

  importCanvas = async (
    sheets: DatabaseSheet[] = [],
    cells: DatabaseCell[] = [],
    edges: DatabaseEdge[] = [],
    logics: DatabaseLogic[] = [],
    columnLabels: DatabaseColumnLabel[] = []
  ) => {
    return supabase.rpc("import_canvas", {
      sheets,
      cells,
      edges,
      logics,
      column_labels: columnLabels,
    })
  }

  autogenerateCanvas = async (query: string, imageBase64?: string) => {
    const { data } = await supabase.functions.invoke("autogenerate_canvas", {
      body: { query, image: imageBase64 },
    })

    return data
  }

  updateColumnLabelsLabel = async (sheetId: string, value: string) => {
    return await supabase
      .from("column_label_label")
      .update({ text: value })
      .eq("sheet_id", sheetId)
  }

  updateRowLabelsLabel = async (sheetId: string, value: string) => {
    return await supabase
      .from("row_label_label")
      .update({ text: value })
      .eq("sheet_id", sheetId)
  }
}
