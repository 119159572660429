import { Handle, Position } from "reactflow"

type HandleConnection = {
  sourceHandleId?: string | null
  handleId?: string | null
  sourceCellY: number
}

type IncomingConnectionHandlesRendererType = {
  handleConnections: HandleConnection[]
  position: "top" | "bottom"
  nodesGap: number
  nodesHalfWidth: number
}

export const IncomingConnectionHandlesRenderer = ({
  handleConnections,
  position,
  nodesGap,
  nodesHalfWidth,
}: IncomingConnectionHandlesRendererType) => {
  return (
    <>
      {handleConnections.map(
        (connection, i) =>
          connection.handleId && (
            <Handle
              key={connection.handleId}
              id={connection.handleId}
              type="target"
              position={Position.Left}
              style={{
                background: "#4B91EC",
                left: 30 - nodesHalfWidth + nodesGap * i + 12,
                top: position === "top" ? "50%" : "auto",
                bottom: position === "top" ? "auto%" : "50",
                transform: position === "top" ? "none" : "translate(0, 50%)",
                opacity: 0,
              }}
              isConnectable={false}
            />
          )
      )}
    </>
  )
}

export default IncomingConnectionHandlesRenderer
