import { Handle, Position } from "reactflow"

type OutgoingConnectionHandlesRendererType = {
  handleIds?: string[]
}

export const OutgoingConnectionHandlesRenderer = ({
  handleIds,
}: OutgoingConnectionHandlesRendererType) => {
  return (
    <>
      {handleIds?.map((handleId) => (
        <Handle
          key={`edge-${handleId}`}
          id={`edge-${handleId}`}
          type="source"
          position={Position.Right}
          style={{
            background: "#4B91EC",
          }}
          isConnectable={false}
        />
      ))}
    </>
  )
}

export default OutgoingConnectionHandlesRenderer
