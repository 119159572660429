import { BaseEdge, ConnectionLineComponentProps, EdgeProps } from "reactflow"
import { getConnectToCellPath } from "../utils/rendering"

export const EdgePreview = (edgeProps: ConnectionLineComponentProps) => {
  const { fromX, fromY, toX, toY } = edgeProps
  const { edgePath } = getConnectToCellPath(fromX, fromY, toX, toY)

  return (
    <BaseEdge
      path={edgePath}
      style={{
        strokeWidth: 1.5,
        strokeOpacity: 0.8,
      }}
    />
  )
}
