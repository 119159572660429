import {
  memo,
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
  MouseEvent,
} from "react"

import {
  NodeResizeControl,
  ResizeDragEvent,
  ResizeParams,
  useReactFlow,
  useStoreApi,
  useUpdateNodeInternals,
} from "reactflow"
import { uuidv7 } from "uuidv7"
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-icon.svg"
import { ReactComponent as TriangleIcon } from "../../assets/icons/triangle.svg"
import { ReactComponent as SheetHandleIcon } from "../../assets/icons/sheet-handle.svg"
import { ReactComponent as ResizeIcon } from "../../assets/icons/resize-handle.svg"
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg"
import { AppContext } from "../../AppContext"
import {
  UISheet,
  UICell,
  UIColumnLabel,
  UINode,
  UIRowLabel,
} from "../../types/UITypes"
import "./SheetRenderer.css"
import { CELL_WIDTH } from "../../utils/constants"
import CellRenderer from "./CellRenderer"
import clsx from "clsx"
import CellContextMenu from "./CellContextMenu"

type CustomNodeProps = { id: string; data: UISheet }

const controlStyle = {
  background: "transparent",
  border: "none",
  transform: "translate(-11px, -11px)",
  zIndex: 100,
}

const HEIGHT_OFFSET = 2 * 43.5

export const MatrixRenderer = ({ id, data }: CustomNodeProps) => {
  const { setNodes } = useReactFlow()
  const store = useStoreApi()
  const {
    selectedNodes,
    setSelectedNodes,
    removeCells,
    connectionStart,
    onConnect,
    updateCell,
    onAddRow,
    onAddColumn,
    onAddCells,
    onShrinkSheet,
    onRemoveColumn,
    setIsEditingLabel,
    isPendingApiResponse,
    isSheetsUIVisible,
    onUpdateColumnLabel,
    onUpdateRowLabel,
    pendingSheetSelectionId,
    setPendingSheetSelectionId,
    onUpdateSheetName,
    onUpdateColumnLabelsLabel,
    onUpdateRowLabelsLabel,
    onOpenMatrixLabelEditor,
    cellWithContextMenu,
    setCellWithContextMenu,
  } = useContext(AppContext)

  const isSelected = useMemo(() => selectedNodes.includes(id), [selectedNodes])
  const isConnectionModeEnabled = useMemo(
    () => !!selectedNodes.length,
    [selectedNodes]
  )
  const [editedCell, setEditedCell] = useState<UICell>()
  const [sheetName, setSheetName] = useState<string>()
  const cellsContainerRef = useRef<HTMLDivElement>(null)
  const initTimeoutRef = useRef<number>()
  const { getNodes } = useReactFlow()

  useEffect(() => {
    setSheetName(data.name)
  }, [data])

  const rows = useMemo(() => {
    const rows: UICell[][] = []

    data.cells.forEach((cell) => {
      if (!rows[cell.rowIndex - 1]) {
        rows[cell.rowIndex - 1] = []
      }
      rows[cell.rowIndex - 1][cell.columnIndex - 1] = cell
    })

    return rows
  }, [data.cells])

  useEffect(() => {
    if (isSelected) {
      setTimeout(() => {
        setResizeState({
          initialWidth: cellsContainerRef.current?.offsetWidth,
          initialHeight: cellsContainerRef.current?.offsetHeight,
        })
      })
    }
  }, [data.cells, isSelected])

  const updateNodeInternals = useUpdateNodeInternals()

  useEffect(() => {
    if (pendingSheetSelectionId) {
      setSelectedNodes([pendingSheetSelectionId])
      setPendingSheetSelectionId(null)
    }
  }, [pendingSheetSelectionId])

  useEffect(() => {
    updateNodeInternals(id)
  }, [data])

  useEffect(() => {
    if (!initTimeoutRef.current) {
      initTimeoutRef.current = window.setTimeout(() => {
        updateNodeInternals(id)
      }, 100)
    }

    return () => {
      if (initTimeoutRef.current) {
        window.clearTimeout(initTimeoutRef.current)
      }
    }
  }, [])

  const handleEditCell = async (
    formulaEditorInlineValue: string,
    editedCell: UICell
  ) => {
    // const formulaRegExp = /^(=)([a-z]{1,})\((?!,)(.*),(.*)\)$/i
    // const formulaString = formulaEditorInlineValue.replaceAll(" ", "")
    // const formulaString = formulaEditorInlineValue

    const updatedCellName = formulaEditorInlineValue

    updateCell(updatedCellName, editedCell.id, id)

    setEditedCell(undefined)
  }

  const handleDeleteSheet = async () => {
    removeCells(data, data.cells, { removeAll: true })
  }

  const handleCellMouseUp = (event: MouseEvent, rowIndex: number) => {
    // if (
    //   event.target ===
    //     cellsContainerRef.current
    //       ?.getElementsByClassName("cell")
    //       [cellIndex]?.getElementsByClassName("target-handle")[0] ||
    //   id === connectionStart?.source
    // ) {
    //   return
    // }

    if (connectionStart) {
      onConnect({
        ...connectionStart,
        target: id,
        targetHandle: `target-sheet-${data.id}-row-${rowIndex}`,
      })
    }
  }

  const getNodeTopPos = (
    cell: UICell,
    i: number,
    nodeType: string,
    edgeHandles: UINode[]
  ) => {
    if (!cellsContainerRef.current || nodeType === "target") {
      return "50%"
    }

    const cellNodes = edgeHandles?.filter(
      (edgeHandle) =>
        edgeHandle.rowIndex === cell.rowIndex &&
        edgeHandle.nodeType === nodeType
    )

    const numGaps = (cellNodes?.length || 1) - 1
    const cellHeight =
      cellsContainerRef.current.getElementsByClassName("sheet-row")[i]
        ?.clientHeight || 40
    const gap = Math.round(Math.min(cellHeight / numGaps, 8))

    return `calc(50% - ${numGaps * (gap / 2)}px + ${i * gap}px)`
  }

  const [columnNumberWithMenu, setColumnNumberWithMenu] = useState(-1)
  const [columnLabels, setColumnLabels] = useState<UIColumnLabel[]>([])
  const [rowLabels, setRowLabels] = useState<UIRowLabel[]>([])
  const [columnLabelsLabel, setColumnLabelsLabel] = useState<string>("")
  const [rowLabelsLabel, setRowLabelsLabel] = useState<string>("")

  useEffect(() => {
    const updatedColumnLabels: UIColumnLabel[] = []
    const dataColumnLabels = data.columnLabels || []

    dataColumnLabels.forEach((columnLabel) => {
      updatedColumnLabels[columnLabel.columnIndex - 1] = columnLabel
    })

    setColumnLabels(updatedColumnLabels)
  }, [data.columnLabels])

  useEffect(() => {
    const updatedRowLabels: UIRowLabel[] = []
    const dataRowLabels = data.rowLabels || []

    dataRowLabels.forEach((rowLabel) => {
      updatedRowLabels[rowLabel.rowIndex - 1] = rowLabel
    })

    setRowLabels(updatedRowLabels)
  }, [data.rowLabels])

  const handleClick = () => {
    setColumnNumberWithMenu(-1)
  }

  useEffect(() => {
    return document.removeEventListener("click", handleClick)
  }, [])

  const isUIVisible = isSheetsUIVisible || isSelected

  const [resizeState, setResizeState] = useState<any>({})

  const getResizedSheetCells = (
    width: number,
    height: number,
    roundUp: boolean
  ) => {
    let numExtraColumns = (width - resizeState.initialWidth) / CELL_WIDTH
    let numExtraRows = (height - resizeState.initialHeight) / 41
    // numExtraColumns = Math.round(numExtraColumns)
    // numExtraRows = Math.round(numExtraRows)

    numExtraColumns = roundUp
      ? Math.ceil(numExtraColumns)
      : Math.round(numExtraColumns)
    numExtraRows = roundUp ? Math.ceil(numExtraRows) : Math.round(numExtraRows)

    let updatedRows =
      numExtraColumns > 0
        ? rows.map((row, rowIndex) =>
            row.concat(
              new Array(numExtraColumns).fill(null).map(
                (_, columnIndex) =>
                  ({
                    id: uuidv7(),
                    sheetId: data.id,
                    columnIndex: row.length + columnIndex + 1,
                    rowIndex: rowIndex + 1,
                    isNew: true,
                  } as unknown as UICell)
              )
            )
          )
        : rows.map((row) => row.slice(0, row.length + numExtraColumns))

    updatedRows =
      numExtraRows > 0
        ? updatedRows.concat(
            new Array(numExtraRows).fill(null).map((_, rowIndex) =>
              new Array(rows[0].length + numExtraColumns).fill(null).map(
                (_, columnIndex) =>
                  ({
                    id: uuidv7(),
                    sheetId: data.id,
                    columnIndex: columnIndex + 1,
                    rowIndex: rows.length + rowIndex + 1,
                    isNew: true,
                  } as unknown as UICell)
              )
            )
          )
        : updatedRows.slice(0, rows.length + numExtraRows)

    if (!updatedRows?.length || !updatedRows[0]?.length) {
      return null
    }

    return {
      updatedRows,
      numColumns: updatedRows.length,
      numRows: updatedRows.length,
    }
  }

  const handleResize = (
    event: ResizeDragEvent,
    params: ResizeParams & { direction: number[] }
  ) => {
    const newWidth = Math.max(params.width - 2, resizeState.initialWidth)
    const newHeight = Math.max(
      params.height - HEIGHT_OFFSET,
      resizeState.initialHeight
    )

    const resizedSheetCells = getResizedSheetCells(newWidth, newHeight, true)

    if (!resizedSheetCells) {
      return
    }

    const { updatedRows } = resizedSheetCells

    setResizeState((resizeState: any) => ({
      ...resizeState,
      width: newWidth,
      height: newHeight,
      virtualRows: updatedRows,
      isActive: true,
    }))
  }

  const handleResizeEnd = (event: ResizeDragEvent, params: ResizeParams) => {
    const newWidth = Math.max(params.width - 2, resizeState.initialWidth)
    const newHeight = Math.max(
      params.height - HEIGHT_OFFSET,
      resizeState.initialHeight
    )
    const resizedSheetCells = getResizedSheetCells(newWidth, newHeight, false)

    if (!resizedSheetCells) {
      return
    }

    const { updatedRows, numColumns, numRows } = resizedSheetCells
    const newCells: any = []

    updatedRows.forEach((rowCells) => {
      rowCells.forEach((cell) => {
        if (cell.isNew) {
          newCells.push(cell)
        }
      })
    })

    setResizeState((resizeState: any) => ({
      ...resizeState,
      width: newWidth,
      height: newHeight,
      virtualRows: [],
      isActive: false,
    }))

    const maxColumns = numColumns < rows[0].length ? numColumns : null
    const maxRows = numRows < rows.length ? numRows : null

    if (maxColumns || maxRows) {
      onShrinkSheet(data.id, maxColumns, maxRows)
    } else if (newCells.length) {
      onAddCells(data.id, newCells, getNodes())
    }
  }

  const matrixRows = resizeState.virtualRows?.length
    ? resizeState.virtualRows
    : rows

  if (!rows.length) {
    return null
  }

  return (
    <div
      key={id}
      className={clsx({
        "selected-sheet-container": isSelected,
      })}
    >
      <div className="sheet-header">
        <SheetHandleIcon />
        <input
          className="column-label-input sheet-name-input"
          value={sheetName || ""}
          onChange={({ target }) => {
            setSheetName(target.value)
          }}
          onFocus={() => setIsEditingLabel(true)}
          onBlur={({ target }) => {
            onUpdateSheetName({ ...data, name: target.value }, target.value)
            setIsEditingLabel(false)
          }}
        />{" "}
        <DeleteIcon className="delete-icon" onClick={handleDeleteSheet} />
      </div>
      {isUIVisible && (
        <NodeResizeControl
          onResize={handleResize}
          onResizeEnd={handleResizeEnd}
          style={controlStyle}
          minWidth={100}
          minHeight={50}
        >
          <ResizeIcon style={{ width: 21, height: 21 }} />
        </NodeResizeControl>
      )}
      <div className="sheet-row matrix-x-label-row">
        <div className="matrix-top-left-cell" style={{ width: CELL_WIDTH }}>
          <div className="matrix-x-label">
            <EditIcon
              className="matrix-label-edit-icon"
              onClick={() =>
                onOpenMatrixLabelEditor(
                  data.id,
                  "columnLabelsLabel",
                  data.columnLabelsLabel
                )
              }
            />
            <span
              className="label-label"
              title={data.columnLabelsLabel}
              onClick={() =>
                onOpenMatrixLabelEditor(
                  data.id,
                  "columnLabelsLabel",
                  data.columnLabelsLabel
                )
              }
              style={{ cursor: "pointer" }}
            >
              {data.columnLabelsLabel || "Columns"}
            </span>
          </div>
          <div className="matrix-y-label">
            <EditIcon
              onClick={() =>
                onOpenMatrixLabelEditor(
                  data.id,
                  "rowLabelsLabel",
                  data.rowLabelsLabel
                )
              }
              className="matrix-label-edit-icon"
            />
            <span
              title={data.rowLabelsLabel}
              className="label-label"
              style={{ cursor: "pointer" }}
              onClick={() =>
                onOpenMatrixLabelEditor(
                  data.id,
                  "rowLabelsLabel",
                  data.rowLabelsLabel
                )
              }
            >
              {data.rowLabelsLabel || "Rows"}
            </span>
          </div>
        </div>
        {rows[0].map((cell: UICell, i: number) => (
          <div
            key={`cell-label-${cell.id}`}
            id={`cell-label-${cell.id}`}
            className={clsx("cell", "header-cell", {
              "highlighted-cell": false,
            })}
            onMouseOver={() => {}}
            onMouseOut={() => {}}
            onMouseUp={() => {}}
            style={{ width: CELL_WIDTH, maxWidth: CELL_WIDTH }}
          >
            <div style={{ padding: 10 }}>
              <input
                className="column-label-input"
                value={columnLabels[i]?.text || ""}
                onChange={({ target }) => {
                  const updatedColumnLabels: UIColumnLabel[] = [...columnLabels]

                  updatedColumnLabels[i] = {
                    id: `${i} + 1`,
                    columnIndex: i + 1,
                    text: target.value,
                  }
                  setColumnLabels(updatedColumnLabels)
                }}
                onFocus={() => setIsEditingLabel(true)}
                onBlur={({ target }) => {
                  onUpdateColumnLabel(data.id, i + 1, target.value)
                  setIsEditingLabel(false)
                }}
              />
              <div
                className="menu-icon"
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  document.addEventListener("click", handleClick)
                  setColumnNumberWithMenu(i)
                }}
              >
                <TriangleIcon className="dropdown-triangle" />
              </div>
              {columnNumberWithMenu === i && (
                <div key={cell.id} className="cell-menu">
                  <div
                    className="cell-menu-item"
                    onClick={() => onAddColumn(data.id, cell.columnIndex)}
                  >
                    Add Column
                  </div>
                  <div
                    className="cell-menu-item"
                    onClick={() => onRemoveColumn(data.id, cell.columnIndex)}
                  >
                    Delete Column
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div
        id={id}
        ref={cellsContainerRef}
        // className={isSelected ? "sheet selected-sheet" : "sheet"}
        className="sheet matrix"
        style={
          resizeState.isActive
            ? {
                width: resizeState.width,
                height: resizeState.height,
                overflow: "hidden",
              }
            : {}
        }
      >
        {matrixRows.map((rowCells: UICell[], rowIndex: number) => (
          <div key={`${data.id}-row-${rowIndex}`} className="sheet-row">
            <div
              key={`row-label-${matrixRows[0].id}`}
              id={`row-label-${matrixRows[0].id}`}
              onMouseOver={() => {}}
              onMouseOut={() => {}}
              onMouseUp={() => {}}
              style={{
                width: CELL_WIDTH,
                maxWidth: CELL_WIDTH,
                background: "#ecf3fc",
              }}
              className="matrix-y-label-cell"
            >
              <input
                className="column-label-input"
                value={rowLabels[rowIndex]?.text || ""}
                onChange={({ target }) => {
                  const updatedRowLabels: UIRowLabel[] = [...rowLabels]

                  updatedRowLabels[rowIndex] = {
                    id: `${rowIndex} + 1`,
                    rowIndex: rowIndex + 1,
                    text: target.value,
                  }
                  setRowLabels(updatedRowLabels)
                }}
                onFocus={() => setIsEditingLabel(true)}
                onBlur={({ target }) => {
                  onUpdateRowLabel(data.id, rowIndex + 1, target.value)
                  setIsEditingLabel(false)
                }}
                onContextMenu={(event) => {
                  event.preventDefault()
                  event.stopPropagation()

                  setCellWithContextMenu({
                    cellId: rowCells[0].id,
                    target: "rowIndex",
                  })
                }}
              />
              <CellContextMenu
                cell={rowCells[0]}
                cellWithContextMenu={cellWithContextMenu}
                sheet={data}
                target="rowIndex"
                showColumnContextMenu={false}
                showRowContextMenu={true}
              />
            </div>
            {rowCells.map((cell: UICell) => (
              <CellRenderer
                key={`cell-renderer-${cell.id}`}
                cell={cell}
                editedCell={editedCell}
                onCellMouseUp={handleCellMouseUp}
                isSheetSelected={isSelected}
                onEditCell={handleEditCell}
                sheet={data}
                rowSize={rowCells.length}
                getNodeTopPos={getNodeTopPos}
                connectionModeEnabled={isConnectionModeEnabled}
                onAddColumn={onAddColumn}
                resizeState={resizeState}
                showColumnContextMenu={true}
                showRowContextMenu={true}
                showConnectionHandles={false}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(MatrixRenderer)
