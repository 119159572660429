import { Edge, Node } from "reactflow"
import { UILogic, UINode, UISheet } from "../types/UITypes"
import { getLogicEdges, getUpdatedSheetAfterEdgeRemoval } from "./common"

export const removeEdgeWithRemainingIncomingConnections = (
  sourceSheet: Node<UISheet>,
  targetLogic: Node<UILogic>,
  edgeSourceNodeId: string,
  edgeTargetNodeId: string,
  nodes: Node<UISheet | UILogic>[]
) => {
  const updatedSourceSheet = getUpdatedSheetAfterEdgeRemoval(
    sourceSheet,
    edgeSourceNodeId
  )

  const updatedTargetLogic: Node<UILogic> = {
    ...targetLogic,
    data: {
      ...targetLogic?.data!,
      edgeHandles: targetLogic.data?.edgeHandles?.filter(
        (node) => node.id !== edgeTargetNodeId
      ),
    },
  }

  return nodes.map((node) => {
    if (node.id === updatedSourceSheet.id) {
      return updatedSourceSheet
    }

    if (node.id === updatedTargetLogic.id) {
      return updatedTargetLogic
    }

    return node
  })
}

export const removeEdgeWithoutRemainingIncomingConnections = (
  edges: Edge<any>[],
  nodes: Node<UISheet | UILogic>[],
  remainingIncomingConnections: UINode[],
  targetLogic: Node<UILogic>,
  targetLogicId: string,
  edgeSource: string,
  edgeSourceNodeId: string,
  removedEdgeId: string
) => {
  const lastRemainingIncomingEdge = edges.find(
    (edge) =>
      edge.targetHandle?.replace("edge-", "") ===
      remainingIncomingConnections[0].id
  )

  if (!lastRemainingIncomingEdge) {
    return null
  }

  const removedLogicOutgoingEdge = edges.find(
    (edge) => `logic-${edge.data.sourceLogicId}` === targetLogic.id
  )

  if (!removedLogicOutgoingEdge) {
    return null
  }

  let edgeToUpdate: Edge<any>

  const result = {
    updatedNodes: nodes
      .filter(
        (node) => !(node.type === "logic" && node.data.id === targetLogicId)
      )
      .map((node) => {
        const sheet: Node<UISheet> = node as Node<UISheet>

        return sheet.id !== edgeSource
          ? sheet
          : {
              ...sheet,
              data: {
                ...sheet.data,
                edgeHandles: sheet.data.edgeHandles.filter(
                  (handle) => handle.id !== edgeSourceNodeId
                ),
              },
            }
      }),
    updatedEdges: edges
      .filter(
        (edge) =>
          edge.id !== removedLogicOutgoingEdge.id &&
          edge.data.id !== removedEdgeId
      )
      .map((edge) => {
        if (edge.id !== lastRemainingIncomingEdge.id) {
          return edge
        }
        edgeToUpdate = {
          ...edge,
          target: removedLogicOutgoingEdge.target,
          targetHandle: removedLogicOutgoingEdge.targetHandle,
          data: {
            ...edge.data,
            targetLogicId: null,
            targetSheetId: removedLogicOutgoingEdge.data.targetSheetId,
            targetRowIndex: removedLogicOutgoingEdge.data.targetRowIndex,
          },
        }
        return edgeToUpdate
      }),
    removedLogicOutgoingEdge,
    lastRemainingIncomingEdge,
  }

  return {
    ...result,
    edgeToUpdate: edgeToUpdate!,
  }
}

export const removeLogicToCellEdge = (
  nodes: Node<UISheet | UILogic>[],
  edges: Edge<any>[],
  sourceLogic: Node<UILogic>,
  targetSheet: Node<UISheet>,
  edgeTargetNodeId: string,
  sourceLogicId: string,
  target: string
) => {
  const updatedTargetSheet = getUpdatedSheetAfterEdgeRemoval(
    targetSheet,
    edgeTargetNodeId
  )

  const logicIncomingEdges = getLogicEdges(sourceLogic, edges, "target")
  const logicOutgoingEdges = getLogicEdges(sourceLogic, edges, "source")

  const removedEdges = [...logicIncomingEdges, ...logicOutgoingEdges]
  const removedEdgesBySourceSheetId = logicIncomingEdges.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.source]: acc[curr.source] ? [...acc[curr.source], curr] : [curr],
    }
  }, {} as Record<string, Edge[]>)

  return {
    updatedNodes: nodes
      .map((node) => {
        if (node.id === target) {
          return updatedTargetSheet
        }

        if (removedEdgesBySourceSheetId[node.id]) {
          const sheet: Node<UISheet> = node as Node<UISheet>

          return {
            ...sheet,
            data: {
              ...sheet.data,
              edgeHandles: sheet.data.edgeHandles.filter(
                (edgeHandle) =>
                  !logicIncomingEdges.some(
                    (edge) =>
                      edge.sourceHandle?.replace("edge-", "") === edgeHandle.id
                  )
              ),
            },
          }
        }

        return node
      })
      .filter(
        (node) => !(node.type === "logic" && node.data.id === sourceLogicId)
      ),
    removedEdges,
  }
}

export const removeCellToCellEdge = (
  nodes: Node<UISheet | UILogic>[],
  sourceSheet: Node<UISheet>,
  targetSheet: Node<UISheet>,
  edgeSourceNodeId: string,
  edgeTargetNodeId: string
) => {
  const updatedSourceSheet = getUpdatedSheetAfterEdgeRemoval(
    sourceSheet,
    edgeSourceNodeId
  )

  const updatedTargetSheet = getUpdatedSheetAfterEdgeRemoval(
    targetSheet,
    edgeTargetNodeId
  )

  return nodes.map((node) => {
    if (node.id === updatedSourceSheet.id) {
      return updatedSourceSheet
    }

    if (node.id === updatedTargetSheet.id) {
      return updatedTargetSheet
    }

    return node
  })
}
