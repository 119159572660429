import { UINode } from "../../types/UITypes"

const MAX_HANDLES_ROW_WIDTH = 60
const MAX_HANDLES_GAP = 8

export const getHanldesGap = (numHandles: number) => {
  return Math.min(MAX_HANDLES_ROW_WIDTH / numHandles, MAX_HANDLES_GAP)
}

export const getHandleIds = (nodes?: UINode[], type?: "source" | "target") => {
  return nodes
    ?.filter(({ nodeType }) => nodeType === type)
    .map((node) => `edge-${node.id}`)
}

export const getHanldesHalfWidth = (numHandles: number, handlesGap: number) => {
  return (handlesGap * (numHandles - 1)) / 2
}
