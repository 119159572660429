// @ts-ignore
import Mexp from "math-expression-evaluator"
import { ApiCell, ApiLogic, PublishedApi } from "../database/SupabaseConnector"
import { CellType } from "../types/SupabaseTypesHelper"
import { SheetField, UILogicType } from "../types/UITypes"

const getTypedValue = (
  passedValue: unknown,
  cellType: CellType,
  requestData: Record<string, unknown>
) => {
  switch (cellType) {
    case CellType.Number: {
      let numericValue = parseFloat(passedValue as string)

      // check if cellName is a reference to another field in the requestData
      if (isNaN(numericValue) && requestData[passedValue as string]) {
        numericValue = parseFloat(requestData[passedValue as string] as string)
      }

      return numericValue
    }

    case CellType.Text: {
      return passedValue
    }

    default: {
      return passedValue
    }
  }
}

const numericOperators = ["eq", "gt", "lt", "ge", "le"]
const numbersOnlyRegexp = /^\d+$/

const getCellType = (cell: ApiCell, requestData: Record<string, unknown>) => {
  const { operator, value, name } = cell
  // cell value might be a reference to another cell / request parameter
  const referenceValue = (value && requestData[value]) || value
  const leftHandSideValue = (name && requestData[name]) || name

  let calculatedCellType = CellType.Text

  if (
    operator &&
    numericOperators.includes(operator) &&
    numbersOnlyRegexp.test(referenceValue as string) &&
    numbersOnlyRegexp.test(leftHandSideValue as string)
  ) {
    calculatedCellType = CellType.Number
  }

  if (
    !calculatedCellType &&
    operator &&
    operator === "eq" &&
    numbersOnlyRegexp.test(referenceValue as string)
  ) {
    calculatedCellType = CellType.Text
  }

  return calculatedCellType
}

const evaluateCell = (
  cell: ApiCell,
  requestData: Record<string, unknown>
): boolean => {
  const { name, value, operator, cellLogicType, conditions } = cell

  console.log(name, value, operator)

  if (cellLogicType && conditions) {
    const evaluatedConditions = conditions.map((condition) =>
      evaluateCell(condition, requestData)
    )

    return cellLogicType.toLowerCase() === "and"
      ? !evaluatedConditions.some((value) => !value)
      : evaluatedConditions.some((value) => value)
  }

  // cell value might be a reference to another cell / request parameter
  const referenceValue = (value && requestData[value]) || value
  const leftHandSideValue = (name && requestData[name]) || name

  let calculatedCellType = CellType.Text

  if (
    operator &&
    numericOperators.includes(operator) &&
    numbersOnlyRegexp.test(referenceValue as string) &&
    numbersOnlyRegexp.test(leftHandSideValue as string)
  ) {
    calculatedCellType = CellType.Number
  }

  if (
    !calculatedCellType &&
    operator &&
    operator === "eq" &&
    numbersOnlyRegexp.test(referenceValue as string)
  ) {
    calculatedCellType = CellType.Text
  }

  if (!name || !referenceValue || !calculatedCellType || !operator) {
    return true
  }

  const cellValue = getTypedValue(
    referenceValue,
    calculatedCellType,
    requestData
  )

  const passedValue = getTypedValue(
    leftHandSideValue,
    calculatedCellType,
    requestData
  )

  switch (operator) {
    case "eq": {
      switch (calculatedCellType) {
        case CellType.Number:
        case CellType.Text: {
          return cellValue === passedValue
        }
      }

      return false
    }

    case "gt": {
      switch (calculatedCellType) {
        case CellType.Number: {
          return (passedValue as number) > (cellValue as number)
        }
      }

      return false
    }

    case "lt": {
      switch (calculatedCellType) {
        case CellType.Number: {
          return (passedValue as number) < (cellValue as number)
        }
      }

      return false
    }

    case "ge": {
      switch (calculatedCellType) {
        case CellType.Number: {
          return (passedValue as number) >= (cellValue as number)
        }
      }

      return false
    }

    case "le": {
      switch (calculatedCellType) {
        case CellType.Number: {
          return (passedValue as number) <= (cellValue as number)
        }
      }

      return false
    }

    default:
      return false
  }
}

export type GetAnswerArgs = {
  cell: ApiCell
  apiSheet: PublishedApi
  inputData: Record<string, unknown>
  isAPICell?: boolean
}

const canBeEvaluatedAsBoolean = (cell: ApiCell) => {
  return (
    !!(cell.name && cell.operator && cell.value) ||
    !!(cell.cellLogicType && cell.conditions)
  )
}

const hasInputData = (cell: ApiCell, inputData: Record<string, unknown>) => {
  const cellHasInputData =
    (inputData[cell.name] !== undefined &&
      inputData[cell.name] !== null &&
      inputData[cell.name] !== "") ||
    (cell.value !== undefined &&
      cell.value !== null &&
      cell.value !== "" &&
      inputData[cell.value] !== undefined &&
      inputData[cell.value] !== null &&
      inputData[cell.value] !== "")

  if (cellHasInputData) {
    return true
  }

  if (cell.cellLogicType && cell.conditions) {
    const someCellConditionHasInputData: boolean[] = cell.conditions.map(
      (apiCell) => hasInputData(apiCell, inputData)
    )

    return someCellConditionHasInputData.some((value) => value)
  }

  return false
}

export const getAnswer = (
  cell: ApiCell | ApiLogic,
  apiSheet: PublishedApi,
  inputData: Record<string, unknown>,
  isAnswerCell?: boolean
): boolean => {
  const cellCanBeEvaluated = canBeEvaluatedAsBoolean(cell as ApiCell)
  const cellHasInputData = hasInputData(cell as ApiCell, inputData)

  if (!isAnswerCell && cellCanBeEvaluated && cellHasInputData) {
    return evaluateCell(cell as ApiCell, inputData)
  }

  if ((cell as ApiLogic)?.logicType !== undefined && cell.prev) {
    const logicCellAnswers = (
      (cell as ApiLogic).prev as Array<ApiCell | ApiLogic>
    ).map((prevCell) => getAnswer(prevCell, apiSheet, inputData))

    return (cell as ApiLogic)?.logicType === UILogicType.AND
      ? !logicCellAnswers.some((answer) => !answer)
      : logicCellAnswers.some((answer) => answer)
  }

  if ((cell.prev as ApiLogic)?.logicType === undefined && cell.prev) {
    const answer = getAnswer(cell.prev as ApiCell, apiSheet, inputData)

    if (answer) {
      // Define data described by the current cell according to the prev cell value.
      // Example: current cell: a === 1, prev cell b > 2. If b eq 3 and a is not defined, then assign inputData.a = 1.
      const apiCell = cell as ApiCell
      if (apiCell.operator === "eq") {
        inputData[apiCell.name] = apiCell.value
      }
    }

    return answer
  }

  if ((cell.prev as ApiLogic)?.logicType !== undefined && cell.prev) {
    const logicCellAnswers = (
      (cell.prev as ApiLogic).prev as Array<ApiCell | ApiLogic>
    ).map((prevCell) => getAnswer(prevCell, apiSheet, inputData))

    return (cell.prev as ApiLogic)?.logicType === UILogicType.AND
      ? !logicCellAnswers.some((answer) => !answer)
      : logicCellAnswers.some((answer) => answer)
  }

  const apiCell = cell as ApiCell

  // Return true if the cell is empty
  return apiCell.name === "" && apiCell.operator === "" && apiCell.value === ""
}

export const executeApi = (
  inputData: any,
  apiSheetId: string,
  api: PublishedApi[]
) => {
  const apiSheet = api.find(({ sheetId }) => sheetId === apiSheetId)

  if (!apiSheet) {
    return null
  }

  const results: any = []

  apiSheet.cells
    .sort((a, b) => a.rowIndex - b.rowIndex)
    .forEach((cell) => {
      const answer = getAnswer(cell, apiSheet, inputData, true)

      results.push(answer)
    })

  const mappedResults = results.map((result: boolean, i: number) => {
    const resultsRow = apiSheet.apiRows[i].sort(
      (a, b) => a.columnIndex - b.columnIndex
    )

    if (!result) {
      return resultsRow.map((cell) => ({ ...cell, value: false }))
    }

    return resultsRow.map((cell) => {
      const mexp = new Mexp()
      let mathExpression = cell.value?.replace("=", "") || ""
      const inputVarNames = Object.keys(inputData)

      let isMathExpression = false

      inputVarNames.forEach((inputVarName) => {
        if (mathExpression.includes(inputVarName)) {
          isMathExpression = true

          mathExpression = mathExpression.replaceAll(
            inputVarName,
            inputData[inputVarName]
          )
        }
      })

      if (isMathExpression) {
        try {
          const evaluationResult = mexp.eval(mathExpression, [], inputData)

          return { ...cell, value: evaluationResult }
        } catch (e) {
          return {
            ...cell,
            value: getTypedValue(
              cell.value,
              getCellType(cell, inputData),
              inputData
            ),
          }
        }
      } else {
        const typedCellValue = getTypedValue(
          cell.value,
          getCellType(cell, inputData),
          inputData
        )

        inputData[cell.name] = typedCellValue

        return {
          ...cell,
          value: typedCellValue,
        }
      }
    })
  })

  return {
    apiSheet: {
      id: apiSheet.sheetId,
      name: apiSheet.sheetName,
    },
    values: mappedResults,
  }
}

export const getApiParams = (
  api: PublishedApi,
  sheetFields: SheetField[]
): object => {
  let apiParams: Record<string, string> = {}

  const apiVariables = sheetFields.reduce((acc, curr) => {
    return curr.alias && curr.name ? { ...acc, [curr.alias]: curr.name } : acc
  }, {})

  api.cells.forEach((cell) => {
    const cellParams = getCellParams(cell, apiVariables, true)
    apiParams = { ...apiParams, ...cellParams }
  })

  api.apiRows.forEach((answerCellsRow) => {
    answerCellsRow.forEach((answerCell) => {
      sheetFields.forEach((sheetField) => {
        if (sheetField.name) {
          if (
            answerCell.name?.includes(sheetField.name) ||
            answerCell.value?.includes(sheetField.name)
          ) {
            apiParams[sheetField.name] = ""
          }
        }
      })
    })
  })

  return { ...apiParams }
}

export const getCellParams = (
  cell: ApiCell | ApiLogic,
  apiVariables: Record<string, string>,
  isAnswerCell?: boolean
): object => {
  let cellResult: Record<string, string> = {}
  const apiCell = cell as ApiCell

  if (
    apiCell.name !== undefined &&
    apiCell.name !== "" &&
    apiCell.operator !== undefined &&
    apiCell.operator !== "" &&
    apiCell.value !== undefined &&
    apiCell.value !== "" &&
    !isAnswerCell
  ) {
    const cellName = (cell as ApiCell).name
    const apiParamName = apiVariables[cellName] || cellName

    cellResult = { [apiParamName]: "" }
  }

  if ((cell as ApiLogic).logicType !== undefined) {
    const prevCells = (cell.prev as Array<ApiCell | ApiLogic>).map((prev) => {
      return getCellParams(prev, apiVariables)
    })

    const prevResult = prevCells.reduce(
      (acc, curr) => ({ ...acc, ...curr }),
      {}
    )

    return { ...cellResult, ...prevResult }
  }

  if ((cell.prev as ApiLogic)?.logicType === undefined && cell.prev) {
    const prevResult = getCellParams(cell.prev as ApiCell, apiVariables)

    return { ...cellResult, ...prevResult }
  }

  if ((cell.prev as ApiLogic)?.logicType !== undefined && cell.prev) {
    const prevCells = (cell.prev as ApiLogic).prev.map((prev) => {
      return getCellParams(prev, apiVariables)
    })

    const prevResult = prevCells.reduce(
      (acc, curr) => ({ ...acc, ...curr }),
      {}
    )

    return { ...cellResult, ...prevResult }
  }

  const conditions = (cell as ApiCell).conditions

  if (conditions) {
    const conditionCells = conditions.map((prev) => {
      return getCellParams(prev, apiVariables)
    })

    const conditionCellsParams = conditionCells.reduce(
      (acc, curr) => ({ ...acc, ...curr }),
      {}
    )

    return { ...cellResult, ...conditionCellsParams }
  }

  return cellResult
}
