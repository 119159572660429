import { uuidv7 } from "uuidv7"
import {
  createUIEdgesData,
  createUILogics,
  createUISheetData,
} from "../database/converters"
import { UILogic, UILogicType, UISheet } from "../types/UITypes"
import { supabase } from "../database/SupabaseConnector"
import { Node, ReactFlowInstance } from "reactflow"
import { DatabaseCell, DatabaseColumnLabel } from "../types/SupabaseTypesHelper"

export const parseAutogenSheets = async (
  importedApiJSON: any,
  canvasid: string,
  reactFlow: ReactFlowInstance
) => {
  if (importedApiJSON.edges?.length) {
    const edgesByDest: any = {}
    importedApiJSON.edges = importedApiJSON.edges.map((edge: any) => ({
      source_sheet_id: edge.source_sheet?.id || null,
      source_row_index: edge.source_sheet?.row_index || null,
      dest_sheet_id: edge.dest_sheet?.id || null,
      dest_row_index: edge.dest_sheet?.row_index || null,
      source_logic_id: edge.source_logic_id || null,
      dest_logic_id: edge.dest_logic_id || null,
    }))

    importedApiJSON.edges.forEach((edge: any) => {
      if (edge.dest_sheet_id && edge.dest_row_index) {
        const key = `${edge.dest_sheet_id}-${edge.dest_row_index}`
        if (!edgesByDest[key]) {
          edgesByDest[key] = []
        }
        edgesByDest[key].push(edge)
      }
    })

    importedApiJSON.edges = importedApiJSON.edges.filter(
      (edge: any) =>
        !edgesByDest[`${edge.dest_sheet_id}-${edge.dest_row_index}`] ||
        edgesByDest[`${edge.dest_sheet_id}-${edge.dest_row_index}`].length < 2
    )

    const newEdges: any = []
    const newLogics: any = []

    Object.keys(edgesByDest).forEach((key) => {
      if (edgesByDest[key].length > 1) {
        const logicId = uuidv7()

        newLogics.push({
          id: logicId,
          logic_type: "AND",
        })

        const destSheetId = edgesByDest[key][0].dest_sheet_id
        const destRowIndex = edgesByDest[key][0].dest_row_index

        edgesByDest[key].forEach((edge: any) => {
          edge.dest_logic_id = logicId
          edge.dest_sheet_id = null
          edge.dest_row_index = null
          newEdges.push(edge)
        })

        newEdges.push({
          id: uuidv7(),
          source_sheet_id: null,
          dest_sheet_id: destSheetId,
          source_row_index: null,
          dest_row_index: destRowIndex,
          source_logic_id: logicId,
          dest_logic_id: null,
        })
      }
    })

    importedApiJSON.edges = [...importedApiJSON.edges, ...newEdges]

    if (newLogics.length) {
      if (!importedApiJSON.logics) {
        importedApiJSON.logics = []
      }

      importedApiJSON.logics = [...importedApiJSON.logics, ...newLogics]
    }
  }

  const sheetIdMapping: Record<string, string> = {}
  const logicIdMapping: Record<string, string> = {}
  const cellsData: DatabaseCell[] = []
  const columnLabelsData: DatabaseColumnLabel[] = []

  importedApiJSON.sheets?.forEach((sheet: any) => {
    const sheetId = uuidv7()
    sheetIdMapping[sheet.id] = sheetId
    sheet.column_labels?.forEach((columnLabel: any, columnIndex: number) => {
      // ignore missing params like created_at, user_id, that automatically created by the DB
      // @ts-ignore
      columnLabelsData.push({
        id: uuidv7(),
        sheet_id: sheetId,
        column_index: columnIndex + 1,
        text: columnLabel,
        canvas_id: canvasid,
      })
    })

    sheet.cells?.forEach((cellsRow: any, rowIndex: number) => {
      cellsRow.forEach((cell: any, columnIndex: number) => {
        // ignore missing params like created_at, user_id, that automatically created by the DB
        // @ts-ignore
        cellsData.push({
          id: uuidv7(),
          column_index: columnIndex + 1,
          row_index: rowIndex + 1,
          name: cell,
          sheet_id: sheetId,
          canvas_id: canvasid,
        })
      })
    })
  })

  importedApiJSON.logics?.forEach((logic: any) => {
    logicIdMapping[logic.id] = uuidv7()
  })

  // TODO: fix types
  const sheetsDimensoions: any = {}

  // TODO: fix types
  cellsData.forEach((cell: any) => {
    if (!sheetsDimensoions[cell.sheet_id]) {
      sheetsDimensoions[cell.sheet_id] = { numColumns: 0, numRows: 0 }
    }

    if (sheetsDimensoions[cell.sheet_id].numColumns < cell.column_index) {
      sheetsDimensoions[cell.sheet_id].numColumns = cell.column_index
    }

    if (sheetsDimensoions[cell.sheet_id].numRows < cell.row_index) {
      sheetsDimensoions[cell.sheet_id].numRows = cell.row_index
    }
  })

  const baseCoords = reactFlow.project({
    x: window.innerWidth * 0.2,
    y: window.innerHeight * 0.3,
  }) ?? { x: 0, y: 0 }

  const columnWidth = 225

  const sheetsData = importedApiJSON.sheets?.map(
    (sheet: any, sheetIndex: number) => {
      const sheetRowIndex = Math.floor(sheetIndex / 3)
      const sheetColumnIndex = sheetIndex % 3

      const currentX =
        baseCoords.x +
        (sheetColumnIndex
          ? importedApiJSON.sheets
              .slice(sheetRowIndex * 3, sheetRowIndex * 3 + sheetColumnIndex)
              .reduce((acc: number, sheet: any) => {
                const sheetColumns =
                  sheetsDimensoions[sheetIdMapping[sheet.id]].numColumns

                return acc + sheetColumns * columnWidth + 250
              }, 0)
          : 0)

      let currentY = baseCoords.y

      if (sheetRowIndex > 0) {
        currentY =
          baseCoords.y +
          importedApiJSON.sheets
            .filter((_: any, i: number) => i % 3 === sheetColumnIndex)
            .reduce((acc: number, sheet: any) => {
              const sheetRows =
                sheetsDimensoions[sheetIdMapping[sheet.id]].numRows

              return acc + sheetRows * 50 + 50
            }, 0)
      }

      const sheetData = {
        id: sheetIdMapping[sheet.id],
        position_x: currentX,
        position_y: currentY,
        name: sheet.name,
        canvas_id: canvasid,
      }

      return sheetData
    }
  )

  const edgesData = importedApiJSON.edges?.map((edge: any, i: number) => ({
    id: uuidv7(),
    source_sheet_id: sheetIdMapping[edge.source_sheet_id],
    dest_sheet_id: sheetIdMapping[edge.dest_sheet_id],
    source_row_index: edge.source_row_index,
    dest_row_index: edge.dest_row_index,
    source_logic_id: logicIdMapping[edge.source_logic_id] || null,
    dest_logic_id: logicIdMapping[edge.dest_logic_id] || null,
    appearance_type: i,
    canvas_id: canvasid,
  }))

  const { data: logicTypesData } = await supabase.from("logic_type").select()

  const defaultRowHeight = 40
  const logicWidth = 80

  const logicsData = importedApiJSON.logics?.map((logic: any) => {
    let posX = undefined
    let posY = undefined

    try {
      // TODO: fix types
      const incomingEdges = edgesData.filter(
        (edge: any) => edge.dest_logic_id === logicIdMapping[logic.id]
      )

      const startX =
        // TODO: fix types
        incomingEdges.reduce((acc: any, edge: any) => {
          const sheet = sheetsData.find(
            // TODO: fix types
            (sheet: any) => sheet.id === edge.source_sheet_id
          )

          const sheetColumns = sheetsDimensoions[sheet.id].numColumns

          return (acc += sheet?.position_x + sheetColumns * columnWidth)
        }, 0) / incomingEdges.length

      posY =
        // add column labels height offset
        defaultRowHeight +
        // TODO: define types
        incomingEdges.reduce((acc: any, edge: any) => {
          const sheet = sheetsData.find(
            (sheet: any) => sheet.id === edge.source_sheet_id
          )
          return (acc +=
            sheet?.position_y + edge.source_row_index * defaultRowHeight)
        }, 0) /
          incomingEdges.length

      const outgoingEdge = edgesData.find(
        // TODO: fix types
        (edge: any) => edge.source_logic_id === logicIdMapping[logic.id]
      )

      const targetSheet = sheetsData.find(
        // TODO: fix types
        (sheet: any) => sheet.id === outgoingEdge.dest_sheet_id
      )

      posX = (startX + targetSheet?.position_x - logicWidth) / 2
    } catch (e) {
      console.log(e)
    }

    return {
      id: logicIdMapping[logic.id],
      logic_type:
        logic.logic_type?.toUpperCase() === "AND"
          ? UILogicType.AND
          : UILogicType.OR,
      canvas_id: canvasid,
      position_x: posX,
      position_y: posY,
    }
  })

  const importedLogics: Node<UILogic>[] = createUILogics(
    logicsData,
    logicTypesData,
    edgesData
  )

  // Add missing cells if any
  // TODO: fix types
  sheetsData.forEach((sheet: any) => {
    const { numColumns, numRows } = sheetsDimensoions[sheet.id]

    for (let columnIndex = 1; columnIndex <= numColumns; columnIndex++) {
      for (let rowIndex = 1; rowIndex <= numRows; rowIndex++) {
        if (
          !cellsData.find(
            // TODO: fix types
            (cell: any) =>
              cell.sheet_id === sheet.id &&
              cell.column_index === columnIndex &&
              cell.row_index === rowIndex
          )
        ) {
          // ignore missing params like created_at, user_id, that automatically created by the DB
          // @ts-ignore
          cellsData.push({
            id: uuidv7(),
            column_index: columnIndex,
            row_index: rowIndex,
            name: "",
            sheet_id: sheet.id,
            canvas_id: canvasid,
          })
        }
      }
    }
  })

  const importedSheets: Node<UISheet>[] = sheetsData.map((sheetData: any) =>
    createUISheetData(
      sheetData,
      cellsData,
      edgesData,
      columnLabelsData,
      null,
      null,
      null
    )
  )

  const importedEdges = createUIEdgesData(edgesData, sheetsData, logicsData)

  return {
    importedSheets,
    importedLogics,
    importedEdges,
    sheetsData,
    cellsData,
    edgesData,
    logicsData,
    columnLabelsData,
  }
}
