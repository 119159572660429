import { Edge, Connection, ReactFlowInstance, Node } from "reactflow"
import { UISheet, UILogic, UIEdge, UILogicType, UICell } from "../types/UITypes"
import { createEdge } from "./canvasHelpers"
import { uuidv7 } from "uuidv7"

export const addUILogicConnection = (
  nodes: Node<UISheet | UILogic>[],
  edges: Edge<UIEdge>[],
  connection: Connection,
  sourceSheetId: string,
  targetSheetId: string,
  sourceRowIndex: string,
  targetRowIndex: string,
  destCellIncomingEdge: Edge<UIEdge>,
  reactFlowInstance: ReactFlowInstance,
  logicType: UILogicType
) => {
  const { source, target, sourceHandle } = connection

  const sourceSheet = reactFlowInstance?.getNode(source!)
  const prevSourceSheet = reactFlowInstance?.getNode(
    destCellIncomingEdge.source
  )
  const prevSourceSheetId = prevSourceSheet?.data.id

  const targetSheet = reactFlowInstance?.getNode(target!)
  const sourceSheetSymbols = Object.getOwnPropertySymbols(sourceSheet)

  const sourceSheetHandleBounds = (sourceSheet as any)[sourceSheetSymbols[0]]
    .handleBounds

  const sourceHandle1Props = sourceSheetHandleBounds?.source?.find(
    ({ id }: any) => id === sourceHandle
  )

  const prevSourceSheetSymbols = Object.getOwnPropertySymbols(sourceSheet)

  const prevSourceSheetHandleBounds = (prevSourceSheet as any)[
    prevSourceSheetSymbols[0]
  ].handleBounds

  const sourceHandle2Props = prevSourceSheetHandleBounds?.source?.find(
    ({ id }: any) => id === destCellIncomingEdge.sourceHandle
  )

  const sourcePoint1X = sourceSheet!.position.x + (sourceSheet!.width || 0)
  const sourcePoint1Y = sourceHandle1Props
    ? sourceSheet!.position.y + sourceHandle1Props.y
    : sourceSheet!.position.y + (sourceSheet!.height || 0) / 2
  const sourcePoint2X =
    prevSourceSheet!.position.x + (prevSourceSheet!.width || 0)

  const sourcePoint2Y = sourceHandle2Props
    ? prevSourceSheet!.position.y + sourceHandle2Props.y
    : prevSourceSheet!.position.y + (prevSourceSheet!.height || 0) / 2
  const targetPointX = targetSheet!.position.x

  const logicPositionX = (sourcePoint1X + sourcePoint2X + targetPointX) / 3
  const logicPositionY = (sourcePoint1Y + sourcePoint2Y) / 2

  const newLogicId = uuidv7()
  const newLogic = {
    id: `logic-${newLogicId}`,
    position: {
      x: logicPositionX,
      y: logicPositionY,
    },
    data: {
      id: newLogicId,
      name: logicType === UILogicType.AND ? "AND" : "OR",
      isNew: true,
      logicType,
      edgeHandles: [
        {
          id: `target-logic-${newLogicId}-node-1`,
          nodeType: "target",
        },
        {
          id: `target-logic-${newLogicId}-node-2`,
          nodeType: "target",
        },
        {
          id: `source-logic-${newLogicId}-node-3`,
          nodeType: "source",
        },
      ],
    },
    width: 50,
    height: 20,
    type: "logic",
  }

  let updatedEdges = edges.filter((edge) => edge.id !== destCellIncomingEdge.id)

  const appearanceType = destCellIncomingEdge.data?.appearanceType || 0

  const newEdges = [
    createEdge(
      prevSourceSheet?.id!,
      `logic-${newLogicId}`,
      prevSourceSheetId,
      null,
      destCellIncomingEdge.data?.sourceRowIndex!,
      null,
      null,
      newLogicId,
      destCellIncomingEdge.sourceHandle || "",
      `edge-target-logic-${newLogicId}-node-1`,
      appearanceType
    ),
    createEdge(
      source!,
      `logic-${newLogicId}`,
      sourceSheetId,
      null,
      parseInt(sourceRowIndex),
      null,
      null,
      newLogicId,
      `edge-source-sheet-${sourceSheet?.data.id}-row-${sourceRowIndex}-node-${
        (sourceSheet?.data.edgeHandles || []).length + 1
      }`,
      `edge-target-logic-${newLogicId}-node-2`,
      appearanceType
    ),
    createEdge(
      `logic-${newLogicId}`,
      target!,
      null,
      targetSheetId,
      null,
      parseInt(targetRowIndex),
      newLogicId,
      null,
      `edge-source-logic-${newLogicId}-node-3`,
      destCellIncomingEdge.targetHandle || "",
      appearanceType
    ),
  ]

  updatedEdges = updatedEdges.concat(newEdges)

  const updatedNodes: Node<UISheet | UILogic>[] = nodes
    .map((node) =>
      node.id !== sourceSheet?.id
        ? node
        : {
            ...node,
            data: {
              ...node.data,
              edgeHandles: [
                ...(node.data.edgeHandles || []),
                {
                  id: `source-sheet-${
                    sourceSheet.data.id
                  }-row-${sourceRowIndex}-node-${
                    (node.data.edgeHandles || []).length + 1
                  }`,
                  rowIndex: parseInt(sourceRowIndex),
                  nodeType: "source",
                },
              ],
            },
          }
    )
    .concat([newLogic])

  return { updatedNodes, updatedEdges, newLogic, newEdges }
}
