import { useMemo } from "react"
import { SupabaseConnector } from "../database/SupabaseConnector"

export const useSupabaseConnector = (canvasId?: string) => {
  const supabaseConnector = useMemo(
    () => new SupabaseConnector(canvasId),
    [canvasId]
  )

  return supabaseConnector
}
