import { uuidv7 } from "uuidv7"
import { UICell, UISheet } from "../types/UITypes"
import { getId } from "./common"

export const getSheetDimensions = (cells: UICell[]) => {
  let sheetRows = 1
  let sheetColumns = 1

  cells.forEach((cell) => {
    if (cell.rowIndex > sheetRows) {
      sheetRows = cell.rowIndex
    }
    if (cell.columnIndex > sheetColumns) {
      sheetColumns = cell.columnIndex
    }
  })

  return [sheetColumns, sheetRows]
}

export const insertRow = (
  cells: UICell[],
  rowIndex: number,
  sheetId: string
) => {
  const [sheetColumns, sheetRows] = getSheetDimensions(cells)

  const newCells = Array(sheetColumns)
    .fill(null)
    .map(
      (_, i) =>
        ({
          id: uuidv7(),
          rowIndex,
          columnIndex: i + 1,
          sheetId,
        } as UICell)
    )

  // Append row
  if (rowIndex > sheetRows) {
    return [cells.concat(newCells), newCells]
  }

  // Insert row
  return [
    cells
      .map((cell) =>
        cell.rowIndex < rowIndex
          ? cell
          : { ...cell, rowIndex: cell.rowIndex + 1 }
      )
      .concat(newCells),
    newCells,
  ]
}

export const insertColumn = (
  sheet: UISheet,
  columnIndex: number,
  sheetId: string
) => {
  const { cells, columnLabels = [] } = sheet
  const [sheetColumns, sheetRows] = getSheetDimensions(cells)

  const newCells = Array(sheetRows)
    .fill(null)
    .map(
      (_, i) =>
        ({
          id: getId(),
          columnIndex,
          rowIndex: i + 1,
          sheetId,
        } as UICell)
    )

  // Append column
  if (columnIndex > sheetColumns) {
    return {
      updatedCells: cells.concat(newCells),
      newCells,
      updatedColumnLabels: columnLabels,
    }
  }

  // Insert column
  const updatedCells = cells
    .map((cell) =>
      cell.columnIndex < columnIndex
        ? cell
        : { ...cell, columnIndex: cell.columnIndex + 1 }
    )
    .concat(newCells)

  const updatedColumnLabels = columnLabels.map((columnLabel) =>
    columnLabel.columnIndex < columnIndex
      ? columnLabel
      : { ...columnLabel, columnIndex: columnLabel.columnIndex + 1 }
  )

  return { updatedCells, newCells, updatedColumnLabels }
}
