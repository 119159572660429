import { Edge, Node } from "reactflow"
import { uuidv7 } from "uuidv7"
import { EDGE_COLORS } from "./rendering"
import { CellType } from "../types/SupabaseTypesHelper"
import { UICell, UILogic, UISheet } from "../types/UITypes"

export function getId() {
  return uuidv7()
}

export function getVectorLength(x: number, y: number) {
  return Math.sqrt(x * x + y * y)
}

export function getUnitVector(x: number, y: number) {
  const magnitude = getVectorLength(x, y)

  return [x / magnitude, y / magnitude]
}

const edgeNodeSuffixRegex = /-node-.*/

export function getElementIdFromHandleId(
  handleId: string,
  elementType: "cell" | "logic",
  elementPosition: "source" | "target"
) {
  return handleId
    .replace(`edge-${elementPosition}-${elementType}-`, "")
    .replace(edgeNodeSuffixRegex, "")
}

export function getAppearanceType(edges: Edge[]) {
  const appearanceTypeDistribution: Record<number, number> = {}

  EDGE_COLORS.forEach((_, i) => {
    appearanceTypeDistribution[i] = 0
  })

  edges.forEach((edge) => {
    appearanceTypeDistribution[edge.data.appearanceType]++
  })

  const usedAppearanceTypes = []

  for (const [key, value] of Object.entries(appearanceTypeDistribution)) {
    usedAppearanceTypes.push({ appearanceType: key, usage: value })
  }

  return parseInt(
    usedAppearanceTypes.sort((a, b) => a.usage - b.usage)[0].appearanceType
  )
}

export const getCellValueType = (
  cellValue: unknown,
  dataSheetCells: UICell[],
  cellName: string | undefined | null
) => {
  const cellType = dataSheetCells.find((dataSheetCell) => {
    const [name, alias] = dataSheetCell.name?.split(":") || []

    return name === cellName || alias === cellName
  })?.valueType

  if (cellType) {
    return cellType
  }

  if (!isNaN(parseFloat(cellValue as string))) {
    return CellType.Number
  }

  return CellType.Text
}

export const getUpdatedSheetAfterEdgeRemoval = (
  sheet: Node<UISheet>,
  removedEdgeNodeId: string
) => {
  const updatedEdgeHandles = sheet.data.edgeHandles.filter(
    (edgeHandle) => edgeHandle.id !== removedEdgeNodeId
  )

  return {
    ...sheet,
    data: {
      ...sheet.data,
      edgeHandles: updatedEdgeHandles,
    },
  }
}

export const getLogicEdges = (
  logic: Node<UILogic>,
  edges: Edge<any>[],
  edgeHandleType: "source" | "target"
) => {
  const logicHandles = logic.data.edgeHandles
    ?.filter((node) => node.nodeType === edgeHandleType)
    .map(({ id }) => id)

  return edges.filter((edge) => {
    const edgeHanlePropName =
      edgeHandleType === "source" ? "sourceHandle" : "targetHandle"

    const handleId = edge[edgeHanlePropName]?.replace("edge-", "")

    return handleId && logicHandles?.includes(handleId)
  })
}

export const fileToBase64 = async (file: File) => {
  return new Promise((resolve) => {
    const fileReader = new FileReader()

    fileReader.onloadend = function () {
      resolve(fileReader.result)
    }

    fileReader.readAsDataURL(file)
  })
}
