import { Auth } from "@supabase/auth-ui-react"
import { ThemeSupa } from "@supabase/auth-ui-shared"
import { supabase } from "../database/SupabaseConnector"
import "./Onboarding.css"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

export const LoginPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    supabase.auth.getSession().then((response) => {
      const {
        data: { session },
      } = response

      if (session) {
        navigate("/")
      }
    })

    const authChangeEvent = supabase.auth.onAuthStateChange(
      (_event, session) => {
        if (session) {
          navigate("/")
        }
      }
    )

    return () => authChangeEvent.data.subscription.unsubscribe()
  }, [])

  return (
    <div style={{ margin: "50px auto", width: "90%", maxWidth: 500 }}>
      <Auth
        supabaseClient={supabase}
        appearance={{ theme: ThemeSupa }}
        providers={[]}
        showLinks={false}
      />
    </div>
  )
}
