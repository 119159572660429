import { memo, useContext, useEffect, useRef, useState } from "react"
import { useEdges, useReactFlow, useUpdateNodeInternals } from "reactflow"
import * as supabaseConnector from "../../database/SupabaseConnector"
import { AppContext } from "../../AppContext"
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg"

import { UILogic, UILogicType } from "../../types/UITypes"

import "./LogicRenderer.css"
import { getElementIdFromHandleId } from "../../utils/common"
import OutgoingConnectionHandlesRenderer from "./OutgoingConnectionHandlesRenderer"
import IncomingConnectionHandlesRenderer from "./IncomingConnectionHandlesRenderer"
import { getHandleIds, getHanldesGap, getHanldesHalfWidth } from "./utils"

type LogicRendererProps = {
  id: string
  data: UILogic
  xPos: number
  yPos: number
}

export const LogicRenderer = ({ id, data, xPos, yPos }: LogicRendererProps) => {
  const updateNodeInternals = useUpdateNodeInternals()
  const edges = useEdges()
  const reactFlowInstance = useReactFlow()
  const rootRef = useRef(null)
  const [isLogicSelectVisible, setLogicSelectVisible] = useState(false)
  const { updateLogic } = useContext(AppContext)

  const incomingHandleIds = getHandleIds(data.edgeHandles, "target")
  const outgoingConnectionHandleIds = getHandleIds(data.edgeHandles, "source")

  const incomingEdges = edges.filter(
    (edge) =>
      edge.targetHandle && incomingHandleIds?.includes(edge.targetHandle)
  )

  const incomingCells = incomingEdges.map((edge) => {
    const connctedSheet = reactFlowInstance.getNode(edge.source)
    const cellId = getElementIdFromHandleId(
      edge.sourceHandle!,
      "cell",
      "source"
    )

    const domCellId = `cell-${cellId}`
    const domCell = document.getElementById(domCellId)

    return {
      sourceHandleId: edge.sourceHandle,
      handleId: edge.targetHandle,
      sourceCellY:
        (connctedSheet?.position.y || 0) +
        (domCell?.offsetTop || 0) +
        (domCell?.offsetHeight || 0) / 2,
    }
  })

  incomingCells.sort((a, b) => a.sourceCellY - b.sourceCellY)

  const sourceConnectionPointsAboveLogicNode = incomingCells.filter(
    ({ sourceCellY }) => sourceCellY <= yPos
  )

  const sourceConnectionPointsBelowLogicNode = incomingCells.filter(
    ({ sourceCellY }) => sourceCellY > yPos
  )

  sourceConnectionPointsAboveLogicNode.sort(
    (a, b) => b.sourceCellY - a.sourceCellY
  )

  useEffect(() => {
    updateNodeInternals(id)
  }, [data, yPos])

  const topHandlesGap = getHanldesGap(
    sourceConnectionPointsAboveLogicNode.length
  )

  const bottomNodesGap = getHanldesGap(
    sourceConnectionPointsBelowLogicNode.length
  )

  const topHandlesHalfWidth = getHanldesHalfWidth(
    sourceConnectionPointsAboveLogicNode.length,
    topHandlesGap
  )

  const bottomNodesHalfWidth = getHanldesHalfWidth(
    sourceConnectionPointsBelowLogicNode.length,
    topHandlesGap
  )

  const handleClick = () => {
    if (!isLogicSelectVisible) {
      setLogicSelectVisible(true)
    }
  }

  const handleDocumentClick = (event: MouseEvent) => {
    if (isLogicSelectVisible && event.target !== rootRef?.current) {
      setLogicSelectVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick)

    return () => {
      document.removeEventListener("click", handleDocumentClick)
    }
  })

  const handleSelectLogicType = async (logicType: UILogicType) => {
    const { data: logicData } = await supabaseConnector.supabase
      .from("logic")
      .update({ logic_type: logicType })
      .eq("id", data.id)
      .select()

    setLogicSelectVisible(false)

    logicData?.length && updateLogic(logicData[0])
  }

  return (
    <div className="logic" onClick={handleClick} ref={rootRef}>
      {data.name.toUpperCase()}
      {isLogicSelectVisible && (
        <div className="logic-selector">
          {data.logicType === UILogicType.AND ? (
            <div onClick={() => handleSelectLogicType(UILogicType.OR)}>OR</div>
          ) : (
            <div onClick={() => handleSelectLogicType(UILogicType.AND)}>
              AND
            </div>
          )}
        </div>
      )}
      <ArrowDownIcon className="arrow-down" />

      <OutgoingConnectionHandlesRenderer
        handleIds={outgoingConnectionHandleIds}
      />

      <IncomingConnectionHandlesRenderer
        handleConnections={sourceConnectionPointsAboveLogicNode}
        position="top"
        nodesGap={topHandlesGap}
        nodesHalfWidth={topHandlesHalfWidth}
      />

      <IncomingConnectionHandlesRenderer
        handleConnections={sourceConnectionPointsBelowLogicNode}
        position="bottom"
        nodesGap={bottomNodesGap}
        nodesHalfWidth={bottomNodesHalfWidth}
      />
    </div>
  )
}

export default memo(LogicRenderer)
