export type UINode = {
  id: string
  nodeType: string
  rowIndex?: number
}

export type UIEntity = {
  isNew?: boolean
  isUpdated?: boolean
}

export type UIColumnLabel = UIEntity & {
  id: string
  text?: string
  columnIndex: number
}

export type UIRowLabel = UIEntity & {
  id: string
  text?: string
  rowIndex: number
}

export type UIMatrixLabelsLabel = UIEntity & {
  id: string
  text?: string
  sheetId: number
}

export type UICell = UIEntity & {
  id: string
  name?: string
  value?: string
  operator?: string
  valueType?: number | null
  rowIndex: number
  columnIndex: number
  nodes?: UINode[]
  sheetId: string
}

export type UIEdge = UIEntity & {
  id: string
  appearanceType: number
  sourceSheetId: string | null
  targetSheetId: string | null
  sourceRowIndex: number | null
  targetRowIndex: number | null
  sourceLogicId: string | null
  targetLogicId: string | null
}

export type UISheet = UIEntity & {
  id: string
  name?: string
  cells: UICell[]
  columnLabels?: UIColumnLabel[]
  rowLabels?: UIRowLabel[]
  columnLabelsLabel?: string
  rowLabelsLabel?: string
  x: number
  y: number
  isDataSheet?: boolean
  edgeHandles: UINode[]
  complex?: boolean
}

export enum UILogicType {
  "AND" = "018f6f25-cf1e-73f8-aafb-b8c999f5cd54",
  "OR" = "018f6f25-cf21-7c4c-aeb6-4e623d007f28",
}

export type UILogic = UIEntity & {
  id: string
  name: string
  logicType: string
  edgeHandles: UINode[]
}

export enum EditorMode {
  "INLINE" = "1",
  "FORM" = "2",
}

export type SheetField = {
  id: string
  name?: string
  alias?: string
  type?: number | null
  isNew?: boolean
  isEdited?: boolean
  rowIndex: number
}

export type PastedCellContent = {
  text?: string
  rowIndex: number
  columnIndex: number
}
